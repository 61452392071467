import { createSlice } from '@reduxjs/toolkit'

export const rgbLedSlice = createSlice({
  name: 'rgbLed',
  initialState: {
    color: {
      r: 255,
      g: 0,
      b: 255,
      a: 1,
    },
    led: 0,
    brightness: 100,
  },
  reducers: {
    setRGBLedColor: (state, action) => {
      state.color = action.payload.color;
      state.led = action.payload.led;
    },
    setRGBLedBrightness: (state, action) => {
      state.brightness = action.payload;
    },
  }
})

// Action creators are generated for each case reducer function
export const { setRGBLedColor, setRGBLedBrightness  } = rgbLedSlice.actions

export default rgbLedSlice.reducer