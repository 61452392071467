import React, {useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import Box from "@mui/material/Box";
import { grey, teal } from '@mui/material/colors';

function Oscilloscope(props) {
  const ref = useRef();

  const drawGrid = (ctx) => {
    let w = ctx.canvas.clientWidth;
    let h = ctx.canvas.clientHeight;
    let dw = w/10;
    let dh = h/10;
    ctx.fillStyle = '#5DB1A2';
    ctx.fillRect(0, 0, w, h);
    ctx.lineWidth = 1;
    ctx.strokeStyle = '#196156';
    ctx.strokeRect(0, 1, w-1, h-1);
    ctx.beginPath();
    for (let n = 0; n < 10; n++) {
      let x = n * dw;
      ctx.moveTo(x, 0);
      ctx.lineTo(x, h);
    }
    for (let n = 0; n < 10; n++) {
      let y = n * dh;
      ctx.moveTo(0, y);
      ctx.lineTo(w, y);
    }
    ctx.stroke();
  }

  useEffect(() => {
    if (ref.current) {
      const ctx = ref.current.getContext('2d');
      drawGrid(ctx);
    }
  }, []);

  return (
    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100vh'}}>
      <Box sx={{width: '-webkit-fill-available', height: '100vh'}}>
        <canvas id="canvas" ref={ref} style={{backgroundColor: '#5DB1A2', width:"100%", height:"-webkit-fill-available" }}></canvas>
      </Box>
      <Box sx={{width: '10rem', height: '100vh', bgcolor: grey[200]}}></Box>
    </Box>
  )
}

Oscilloscope.propTypes = {}

export default Oscilloscope
