import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import ApplicationBar from '../../components/ApplicationBar';
import { useParams } from 'react-router';
import Knob from "../../components/Knob";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from 'react-redux';
import { setLEDBrightness } from '../../reducers/led/ledSlice';

const LedControl = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const value = useSelector(state => state.led.brightness1);
  const [power, setPower] = useState(true);

  const getLedName = (index) => {
    index = parseInt(index, 10) || 0;
    index = index + 1;
    return `Led ${index}`;
  }

  const handleKnobChange = (newValue) => {
    dispatch(setLEDBrightness({led: id, brightness: newValue}));
    if(newValue === 0) {
      setPower(false);
    } else {
      setPower(true);
    }
  };

  const handlePowerClick = () => {
    if(power) {
      dispatch(setLEDBrightness({led: id, brightness: 0}));
      setPower(false);
    } else {
      dispatch(setLEDBrightness({led: id, brightness: 255}));
      setPower(true);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <ApplicationBar back="/led" title={'LED Brightness'} />
      </header>
      <Box sx={{ color: '#FFF', bgcolor: 'background.paper', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 2, flexDirection: 'column' }}>
        <div className="center">
          <div className="temperature">
            <div
              className={`power ${power ? "on" : "off"}`}
              onClick={handlePowerClick}
            >
              <FontAwesomeIcon icon={faPowerOff} />
            </div>
            <Knob
              fgColor="#FF645A"
              bgColor="#FEFEFE"
              inputColor="#FFFFFF"
              value={value}
              onChange={handleKnobChange}
              min={0}
              max={255}
              lineCap="round"
              thickness={0.15}
              angleOffset={220}
              angleArc={280}
              displayInput={false}
              displayCustom={() => (
                <span className="temp">
                  {value}
                </span>
              )}
              className="knob"
            ></Knob>
          </div>
        </div>
        <Typography variant="h1" component="div" sx={{ flexGrow: 1, textAlign: 'center', fontSize: '3rem', fontWeight: 700, color: '#000', marginTop: '5rem' }}>
          {
            getLedName(id)
          }
        </Typography>
      </Box>
    </div>
  )
};

LedControl.propTypes = {}

export default LedControl
