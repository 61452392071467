import { createSlice } from '@reduxjs/toolkit'

export const inputsSlice = createSlice({
  name: 'inputs',
  initialState: {
    switch1: false,
    switch2: false,
    switch3: false,
    button1: Date(2022,11,14,0,0,0,0),
    button2: Date(2022,11,14,0,0,0,0),
    button3: Date(2022,11,14,0,0,0,0),
    button4: Date(2022,11,14,0,0,0,0),
    button5: Date(2022,11,14,0,0,0,0),
    dial1: 0,
    dial2: 0,
  },
  reducers: {
    setSwitch1: (state, action) => {
      state.switch1 = action.payload;
    },
    setSwitch2: (state, action) => {
      state.switch2 = action.payload;
    },
    setSwitch3: (state, action) => {
      state.switch3 = action.payload;
    },
    button1Toggled: (state, action) => {
      state.button1 = Date.now();
    },
    button2Toggled: (state, action) => {
      state.button2 = Date.now();
    },
    button3Toggled: (state, action) => {
      state.button3 = Date.now();
    },
    button4Toggled: (state, action) => {
      state.button4 = Date.now();
    },
    button5Toggled: (state, action) => {
      state.button5 = Date.now();
    },
    setDial1: (state, action) => {
      state.dial1 = Math.floor(action.payload);
    },
    setDial2: (state, action) => {
      state.dial2 = Math.floor(action.payload);
    },
  }
})

// Action creators are generated for each case reducer function
export const { setSwitch1, setSwitch2, setSwitch3, button1Toggled, button2Toggled, button3Toggled, button4Toggled, button5Toggled, setDial1, setDial2 } = inputsSlice.actions

export default inputsSlice.reducer