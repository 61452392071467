import React from 'react'
import PropTypes from 'prop-types'
import { Outlet, Link } from "react-router-dom";
import Paper from '@mui/material/Paper';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ApplicationBar from '../../components/ApplicationBar';
import { useMediaQuery, useTheme } from '@mui/material';

function Emulators(props) {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div className="App">
      <header className="App-header">
        <ApplicationBar back="/" title={'Hardware Emulators'} />
      </header>
      <ImageList sx={{ my:1, mx:1, backgroundColor: "#FFF" }} cols={matchDownMd ? 2 : 4} gap={8}>
        {itemData.map((item) => (
          <Paper key={item.img} elevation={3}>
            <ImageListItem sx={{borderWidth: 1, borderStyle: "solid", borderColor: "#CCC", padding:1}}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
              <Link to={item.slug}>
                <ImageListItemBar
                  title={item.title}
                  subtitle={item.author}
                  actionIcon={
                    <IconButton
                      sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                      aria-label={`info about ${item.title}`}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  }
                  sx={{fontSize: '2rem', fontWeight: 700}}
                />
              </Link>
            </ImageListItem>
          </Paper>
        ))}
      </ImageList>
    </div>
  );
}

const itemData = [
  {
    img: '/images/1602.png',
    title: '16x2 LCD',
    slug: '/emulators/1602',
    author: '',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: '/images/keypad.png',
    title: 'Membrane Keypad',
    slug: '/emulators/mebrane',
    author: '',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: '/images/stream_deck.png',
    title: 'Stream Deck',
    slug: '/emulators/stream_deck',
    author: '',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: '/images/buzzer.png',
    title: 'Piezo Buzzer',
    slug: '/emulators/buzzer',
    author: '',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: '/images/dotmatrix.png',
    title: 'Dot Matix',
    slug: '/emulators/dotmatrix',
    author: '',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: '/images/reed-switch.png',
    title: 'Reed Switch',
    slug: '/emulators/reed-switch',
    author: '',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: '/images/gsm.png',
    title: 'GSM',
    slug: '/emulators/gsm',
    author: '',
    rows: 2,
    cols: 2,
    featured: true,
  }
];

Emulators.propTypes = {}

export default Emulators
