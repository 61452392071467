import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import store from './store';
import { Provider } from 'react-redux';
import App from './routes/App';
import Main from './routes/Main';
import ErrorPage from './routes/ErrorPage';
import ServoList from './routes/ServoList';
import ServoControl from './routes/ServoControl';
import LedList from './routes/LedList';
import LedControl from './routes/LedControl';
import SerialCommunication from './routes/SerialCommunication';
import PinsMonitor from './routes/PinsMonitor';
import MotorControl from './routes/MotorControl';
import PhoneSensors from './routes/PhoneSensors';
import Camera from './routes/Camera';
import Inputs from './routes/Inputs';
import Emulators from './routes/Emulators';
import MembraneKeypad from './routes/MembraneKeypad';
import ComingSoon from './routes/ComingSoon';
import CodeEditor from './routes/CodeEditor';
import RGBLed from './routes/RGBLed';
import STEMProjects from './routes/Projects';
import Music from './routes/Music';
import Gamepad from './routes/Gamepad';
import HomeAutomation from './routes/HomeAutomation';
import IoT from './routes/IoT';
import LCD1602 from './routes/LCD1602';
import Notifications from './routes/Notifications';
import TTS from './routes/TTS';
import NFC from './routes/NFC';
import SpeechCommandsRecognition from './routes/SpeechCommandsRecognition';
import Oscilloscope from './routes/Oscilloscope';
import DataLogging from './routes/DataLogging';
import ColorDetection from './routes/ColorDetection';
import Login from './routes/Login';
import Register from './routes/Register';
import ForgotPassword from './routes/ForgotPassword';
import StreamDeck from './routes/StreamDeck';

import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Main />
      },
      {
        path: "/servo",
        element: <ServoList />
      },
      {
        path: "/servo/:id",
        element: <ServoControl />
      },
      {
        path: "/led",
        element: <LedList />
      },
      {
        path: "/led/:id",
        element: <LedControl />
      },
      {
        path: "/serial-communication",
        element: <SerialCommunication />
      },
      {
        path: "/monitor",
        element: <PinsMonitor />
      },
      {
        path: "/motor",
        element: <MotorControl />
      },
      {
        path: "/sensors",
        element: <PhoneSensors />
      },
      {
        path: "/camera",
        element: <Camera />
      },
      {
        path: "/inputs",
        element: <Inputs />
      },
      {
        path: "/music",
        element: <Music />
      },
      {
        path: "/nfc",
        element: <NFC />
      },
      {
        path: "/gamepad",
        element: <Gamepad />
      },
      {
        path: "/tts",
        element: <TTS />
      },
      {
        path: "/iot",
        element: <IoT />
      },
      {
        path: "/gsm",
        element: <ComingSoon />
      },
      {
        path: "/speech-recognition",
        element: <SpeechCommandsRecognition />
      },
      {
        path: "/home-automation",
        element: <HomeAutomation />
      },
      {
        path: "/oscilloscope",
        element: <Oscilloscope />
      },
      {
        path: "/editor",
        element: <CodeEditor />
      },
      {
        path: "/projects",
        element: <STEMProjects />
      },
      {
        path: "/camera/capture",
        element: <ComingSoon />
      },
      {
        path: "/camera/colordetection",
        element: <ColorDetection />
      },
      {
        path: "/camera/linefollow",
        element: <ComingSoon />
      },
      {
        path: "/camera/facedetection",
        element: <ComingSoon />
      },
      {
        path: "/emulators",
        element: <Emulators />
      },
      {
        path: "/emulators/mebrane",
        element: <MembraneKeypad />
      },
      {
        path: "/emulators/1602",
        element: <LCD1602 />
      },
      {
        path: "/emulators/buzzer",
        element: <ComingSoon />
      },
      {
        path: "/emulators/gsm",
        element: <ComingSoon />
      },
      {
        path: "/emulators/dotmatrix",
        element: <ComingSoon />
      },
      {
        path: "/emulators/reed-switch",
        element: <ComingSoon />
      },
      {
        path: "/rgb-strip",
        element: <RGBLed />
      },
      {
        path: "/iot/notifications",
        element: <Notifications />
      },
      {
        path: "/iot/data-logging",
        element: <DataLogging />
      },
      {
        path: "/login",
        element: <Login />
      },
      {
        path: "/register",
        element: <Register />
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />
      },
      {
        path: "/emulators/stream_deck",
        element: <StreamDeck />
      }
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
