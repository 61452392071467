import { createSlice } from '@reduxjs/toolkit'

export const ledSlice = createSlice({
  name: 'led',
  initialState: {
    brightness1 : 128,
    brightness2 : 128,
    brightness3 : 128,
    brightness4 : 128,
    brightness5 : 128,
    brightness6 : 128,
    brightness7 : 128,
    brightness8 : 128,
    brightness9 : 128,
    brightness10 : 128,
  },
  reducers: {
    setLEDBrightness: (state, action) => {
      const ledIndex = parseInt(action.payload.led, 10);
      const brightness = parseInt(action.payload.brightness);
      if(ledIndex >= 0 && ledIndex < 10) {
        if(brightness >= 0 && brightness <= 255) {
          switch(ledIndex) {
            case 0:
              state.brightness1 = brightness;
              break;
            case 1:
              state.brightness2 = brightness;
              break;
            case 2:
              state.brightness3 = brightness;
              break;
            case 3:
              state.brightness4 = brightness;
              break;
            case 4:
              state.brightness5 = brightness;
              break;
            case 5:
              state.brightness6 = brightness;
              break;
            case 6:
              state.brightness7 = brightness;
              break;
            case 7:
              state.brightness8 = brightness;
              break;
            case 8:
              state.brightness9 = brightness;
              break;
            case 9:
              state.brightness10 = brightness;
              break;
            default:
              break;
          }
        }
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const { setLEDBrightness } = ledSlice.actions

export default ledSlice.reducer