import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ApplicationBar from '../../components/ApplicationBar';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RadarOutlinedIcon from '@mui/icons-material/RadarOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import NfcOutlinedIcon from '@mui/icons-material/NfcOutlined';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import { setReadData } from '../../reducers/nfc/nfcSlice';
import './index.css';
import '../SpeechCommandsRecognition/index.css';

function NFC(props) {
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const [isScanning, setIsScanning] = useState(false);
  const [ndef, setNDef] = useState(null);
  const [ctrl, setCtrl] = useState(null);
  const [nfcRecordType, setNFCRecordType] = useState('');
  const [nfcRecordData, setNFCRecordData] = useState('');
  const [nfcSerialNumber, setNFCSerialNumber] = useState('');
  const [writeNFCRecordType, setWriteNFCRecordType] = useState('text');
  const [writeNFCRecordData, setWriteNFCRecordData] = useState('');
  const [isWritingTag, setIsWritingTag] = useState(false);

  const onTagReadError = (e) => {
    console.log('Error reading tag', e);
  };

  const writeDataToTag = (e) => {
    e.preventDefault();
    setIsWritingTag(true);
    if (!ndef) {
      return;
    }
    if(writeNFCRecordType === 'text') {
      ndef.write(writeNFCRecordData).then(() => {
        setIsWritingTag(false);
      }).catch(error => {
        setIsWritingTag(false);
        console.log('Error writing to tag', error);
      });
    } else if(writeNFCRecordType === 'url') {
      ndef.write({records: [{ recordType: "url", data: writeNFCRecordData }]}).then(() => {
        setIsWritingTag(false);
      }).catch(error => {
        setIsWritingTag(false);
        console.log('Error writing to tag', error);
      });
    }
  };

  const onTagRead = (ndef) => {
    setNFCSerialNumber(ndef.serialNumber);
    const decoder = new TextDecoder();
    for (const record of ndef.message.records) {
      dispatch(setReadData({serialNumber: ndef.serialNumber, recordType: record.recordType, data: decoder.decode(record.data)}));
      setTimeout(() => {
        dispatch(setReadData({serialNumber: "", recordType: "", data: ""}));
      }, 2000);
      setNFCRecordType(record.recordType);
      setNFCRecordData(decoder.decode(record.data));
    }
  };

  useEffect(() => {
    try {
      if(!window.NDEFReader) {
        throw new Error('NDEFReader is not supported by this browser');
      }
      if(!ndef) {
        const nfc = new window.NDEFReader();
        setNDef(nfc);
      }
    }
    catch (error) {
      console.log(error);
    }
  }, [ndef]);

  const startNFCScanning = (e) => {
    e.preventDefault();
    if(isScanning) {
      if(ctrl) {
        ctrl.abort();
      }
      setIsScanning(false);
    } else {
      if(ndef) {
        try {
          const aCtrl = new AbortController();
          setCtrl(aCtrl);
          ndef.scan({ signal: aCtrl.signal }).then(() => {
            ndef.addEventListener("readingerror", onTagReadError);
            ndef.addEventListener("reading", onTagRead);
            console.log("Scan started successfully.");
          }).catch((error) => {
            console.log(`Error! Scan failed to start: ${error}.`);
          });
          setIsScanning(true);
        }
        catch (error) {
          console.log(error);
        }
      }
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <ApplicationBar back="/" title={'NFC'} />
      </header>
      <Box sx={{mt:1, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', overflowY: 'scroll'}}>
      {
          (tab === 0) && (
            <>
              <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', m:5, mt:5, pt:5, pb:5}}>
                <IconButton onClick={startNFCScanning} color="primary" size="large" sx={{mt: 5, mb: 5}}>
                  <div className="pulse-ring nfc-icon" style={{animation: isScanning ? 'pulsate infinite 1.5s' : ''}}>
                    <NfcOutlinedIcon sx={{fontSize: '7rem'}}/>
                  </div>
                </IconButton>
              </Box>
              <Box sx={{mt:2, ml:2, mr:2, width: '-webkit-fill-available'}}>
                <TextField
                  id="nfc-serial-number"
                  label="Serial Number"
                  value={nfcSerialNumber}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{mt: 0, width: '-webkit-fill-available'}}
                />
              </Box>
              <Box sx={{mt:2, ml:2, mr:2, width: '-webkit-fill-available'}}>
                <TextField
                  id="nfc-record-type"
                  label="Record Type"
                  value={nfcRecordType}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{mt: 0, width: '-webkit-fill-available'}}
                />
              </Box>
              <Box sx={{mt:2, ml:2, mr:2, width: '-webkit-fill-available'}}>
                <TextField
                  id="nfc-record-data"
                  label="Record Data"
                  value={nfcRecordData}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{mt: 0, width: '-webkit-fill-available'}}
                />
              </Box>
            </>
          )
        }
        {
          (tab === 1) && (
            <>
              <Box sx={{mt:2, ml:2, mr:2, width: '-webkit-fill-available'}}>
                <FormControl fullWidth>
                  <InputLabel id="nfc-record-type-label">Record Type</InputLabel>
                  <Select
                    labelId="nfc-record-type-label"
                    id="nfc-record-type"
                    value={writeNFCRecordType}
                    label="Record Type"
                    sx={{textAlign: "left"}}
                    onChange={e => setWriteNFCRecordType(e.target.value)}
                  >
                    <MenuItem value={'text'}>Text</MenuItem>
                    <MenuItem value={'url'}>URL</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{mt:2, ml:2, mr:2, width: '-webkit-fill-available'}}>
                <TextField
                  id="nfc-record-data"
                  label="Record Data"
                  value={writeNFCRecordData}
                  InputProps={{
                    readOnly: false,
                  }}
                  sx={{mt: 0, width: '-webkit-fill-available'}}
                  onChange={e => setWriteNFCRecordData(e.target.value)}
                />
              </Box>
              <Box sx={{mt:2, ml:5, mr:5, width: '-webkit-fill-available'}}>
                <Button variant="contained" disabled={isWritingTag} sx={{mt:2, ml:5, mr:5, pl:5, pr:5}} onClick={writeDataToTag}>Write</Button>
              </Box>
              {
                (isWritingTag) && (
                  <Box sx={{mt:2, ml:5, mr:5, width: '-webkit-fill-available'}}>
                    <span>Tap on the NFC tag to write the data.</span>
                  </Box>
                )
              }
            </>
          )
        }
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%' }} elevation={3}>
          <BottomNavigation
            showLabels
            value={tab}
            onChange={(event, newValue) => {
              setTab(newValue);
            }}
          >
            <BottomNavigationAction label="Scan" icon={<RadarOutlinedIcon fontSize="large"/>} />
            <BottomNavigationAction label="Write" icon={<CreateOutlinedIcon fontSize="large"/>} />
          </BottomNavigation>
        </Paper>
      </Box>
    </div>
  )
}

NFC.propTypes = {}

export default NFC
