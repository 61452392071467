import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ApplicationBar from '../../components/ApplicationBar';
import ComingSoonSVG from "./comingsoon.svg";

function ComingSoon(props) {
  return (
    <div className="App">
      <header className="App-header">
        <ApplicationBar back="/" />
      </header>
      <Box sx={{mt:2, display:"flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
        <img src={ComingSoonSVG} alt="coming soon" style={{margin: '2rem', marginLeft: '3rem', marginRight: '3rem'}} />
        <Typography component="div" variant="h3" sx={{fontWeight: 700}}>
          Coming Soon
        </Typography>
      </Box>
    </div>
  )
}

ComingSoon.propTypes = {}

export default ComingSoon
