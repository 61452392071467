import { createSlice } from '@reduxjs/toolkit'

export const nfcSlice = createSlice({
  name: 'nfc',
  initialState: {
    serialNumber: "",
    recordType: "",
    data: "",
  },
  reducers: {
    setReadData: (state, action) => {
      state.serialNumber = action.payload.serialNumber;
      state.recordType = action.payload.recordType;
      state.data = action.payload.data;
    }
  }
})

// Action creators are generated for each case reducer function
export const { setReadData  } = nfcSlice.actions

export default nfcSlice.reducer