import React from 'react'
import PropTypes from 'prop-types'
import { Outlet, Link } from "react-router-dom";
import Paper from '@mui/material/Paper';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ApplicationBar from '../../components/ApplicationBar';

function Camera(props) {
  return (
    <div className="App">
      <header className="App-header">
        <ApplicationBar back="/" title={'Camera'} />
      </header>
      <ImageList sx={{ my:1, mx:1, backgroundColor: "#FFF" }} gap={8}>
        {itemData.map((item) => (
          <Paper key={item.img} elevation={3}>
            <ImageListItem sx={{borderWidth: 1, borderStyle: "solid", borderColor: "#CCC", padding:1}}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
              <Link to={item.slug}>
                <ImageListItemBar
                  title={item.title}
                  subtitle={item.author}
                  actionIcon={
                    <IconButton
                      sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                      aria-label={`info about ${item.title}`}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  }
                  sx={{fontSize: '2rem', fontWeight: 700}}
                />
              </Link>
            </ImageListItem>
          </Paper>
        ))}
      </ImageList>
    </div>
  );
}

const itemData = [
  {
    img: '/images/cameracapture.png',
    title: 'Image & Video',
    slug: '/camera/capture',
    author: '',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: '/images/colordetection.png',
    title: 'Color Detection',
    slug: '/camera/colordetection',
    author: '',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: '/images/linefollow.png',
    title: 'Line Following',
    slug: '/camera/linefollow',
    author: '',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: '/images/facedetection.png',
    title: 'Face Detection',
    slug: '/camera/facedetection',
    author: '',
    rows: 2,
    cols: 2,
    featured: true,
  }
];

Camera.propTypes = {}

export default Camera
