import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ApplicationBar from '../../components/ApplicationBar';
import { ChromePicker, GithubPicker } from 'react-color';
import { useSelector, useDispatch } from 'react-redux';
import { setRGBLedColor, setRGBLedBrightness  } from '../../reducers/rgbLed/rgbLedSlice';
import Brightness3OutlinedIcon from '@mui/icons-material/Brightness3Outlined';
import Brightness1OutlinedIcon from '@mui/icons-material/Brightness1Outlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';

function RGBLed(props) {
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const ledIndex = useSelector(state => state.rgbLed.led);
  const ledColor = useSelector(state => state.rgbLed.color);
  const ledBrightness = useSelector(state => state.rgbLed.brightness);
  const [led, setLED] = useState(ledIndex+1);

  const rgbToHex = (color) => {
    if(color === undefined) {
      return '#000000';
    }
    return '#' + [color.r, color.g, color.b].map(x => {
      const hex = x.toString(16)
      return hex.length === 1 ? '0' + hex : hex
    }).join('');
  }

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  const [color, setColor] = useState({hex: rgbToHex(ledColor), rgb: ledColor});
  const [brightness, setBrightness] = useState(ledBrightness);

  const handleColorChange = c => {
    setColor(c);
  }

  const handleSetLedColor = (e) => {
    e.preventDefault();
    const ledIndex = (parseInt(led, 10) || 0) - 1;
    dispatch(setRGBLedColor({led: ledIndex, color: color.rgb}));
  };

  const handleSetAllLedsColor = (e) => {
    e.preventDefault();
    dispatch(setRGBLedColor({led: -1, color: color.rgb}));
  };

  const handleLEDBrightnessChange = (e, value) => {
    e.preventDefault();
    setBrightness(value);
  }

  const handleSetLEDBrightness = (e) => {
    e.preventDefault();
    dispatch(setRGBLedBrightness(brightness));
  };

  return (
    <div className="App">
      <header className="App-header">
        <ApplicationBar back="/" title={'RGB LED'} />
      </header>
      <Box>
        {tab === 0 && (
          <Box sx={{mt:4, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', mb: '4rem'}}>
            <Box sx={{ml: 4, mr: 4, mt: 0, width:'-webkit-fill-available'}}>
              <ChromePicker width='-webkit-fill-available' color={color} onChangeComplete={handleColorChange} />
            </Box>
            <Box sx={{ml: 4, mr: 4, mt: 2, width:'-webkit-fill-available'}}>
              <GithubPicker width='-webkit-fill-available' triangle={'hide'} color={color} onChangeComplete={handleColorChange} />
            </Box>
            <Box sx={{mt:5, display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
              <TextField id="led-number" label="LED Position" variant="standard"
                type="number" value={led} placeholder={'1'} onChange={e => setLED(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{mr: 3}}/>
              <Button variant="contained" onClick={handleSetLedColor}>Set Color</Button>
            </Box>
            <Box sx={{mt:5, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width: '-webkit-fill-available', ml:3, mr:3}}>
              <Stack direction="row" spacing={1} sx={{alignItems:"center", width: '-webkit-fill-available'}}>
                <Brightness3OutlinedIcon sx={{transform: 'rotate(180deg)'}}/>
                <Slider value={brightness} aria-label="Default" min={0} max={255} valueLabelDisplay="auto" onChange={handleLEDBrightnessChange} />
                <Brightness1OutlinedIcon/>
              </Stack>
              <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', width: '-webkit-fill-available'}}>
                <Button variant="contained" sx={{mt:2, mr:2}} onClick={handleSetAllLedsColor}>Set Color</Button>
                <Button variant="contained" sx={{mt:2}} onClick={handleSetLEDBrightness}>Set Brightness</Button>
              </Box>
            </Box>
          </Box>
        )}
        {tab === 1 && (
          <Box sx={{mt:1, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
            <List sx={{width: '-webkit-fill-available'}} alignItems="center">
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <PlayCircleFilledOutlinedIcon fontSize="large"/>
                  </ListItemIcon>
                  <ListItemText primary="Strobe" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <PlayCircleFilledOutlinedIcon fontSize="large"/>
                  </ListItemIcon>
                  <ListItemText primary="Halloween Eyes" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <PlayCircleFilledOutlinedIcon fontSize="large"/>
                  </ListItemIcon>
                  <ListItemText primary="Twinkle" />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        )}
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%' }} elevation={3}>
          <BottomNavigation
            showLabels
            value={tab}
            onChange={(event, newValue) => {
              setTab(newValue);
            }}
          >
            <BottomNavigationAction label="Led" icon={<LightModeOutlinedIcon fontSize="large"/>} />
            <BottomNavigationAction label="Effects" icon={<PaletteOutlinedIcon fontSize="large"/>} />
          </BottomNavigation>
        </Paper>
      </Box>
    </div>
  )
}

RGBLed.propTypes = {}

export default RGBLed
