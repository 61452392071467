import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import './index.css';

function LCD(props) {
  const imageRef = useRef(null);
  const [imageRatio, setImageRatio] = useState(1);
  const [imageTop, setImageTop] = useState(0);
  const [imageLeft, setImageLeft] = useState(0);
  const lines = useSelector(state => state.lcd1602.lines);

  const onWindowResize = (e) => {
    const imageWidth = imageRef.current.clientWidth;
    const rt = imageRef.current.getBoundingClientRect();
    setImageTop(rt.top);
    setImageLeft(rt.left);
    setImageRatio(imageWidth/849);
  };

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    setTimeout(() => {
      onWindowResize();
    }, 500);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  return (
    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'self-start', ml: 2, mr: 2, mt:2, mb:2, height: `calc(100vh - 96px)`, position: 'relative'}}>
      <img ref={imageRef} src="/images/16x02lcd.png" alt="LCD1602" style={{height: 'auto', maxWidth: '-webkit-fill-available', maxHeight: '-webkit-fill-available'}} />
      {
        lines.map((line, lineIndex) => {
          return line.map((char, charIndex) => {
            return (
              <Box key={`line${lineIndex}-char${charIndex}`} className={"lcd-text"}
                sx={{top: `${((char.y-11)*imageRatio)}px`, left: `${((char.x)*imageRatio)}px`, fontSize: `${imageRatio*60}px`}}>
                  {char.char}
              </Box>
            );
          });
        })
      }
    </Box>
  )
}

LCD.propTypes = {}

export default LCD
