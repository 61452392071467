import { createSlice } from '@reduxjs/toolkit'

export const streamDeckSlice = createSlice({
  name: 'streamDeck',
  initialState: {
    keys: [
      {
        name: 'GMail',
        img: '/images/gmail.png',
        command: 'url',
        params: 'https://mail.google.com/mail/u/0/#inbox',
      },
      {
        name: 'Classroom',
        img: '/images/classroom.png',
        command: 'url',
        params: 'https://classroom.google.com/u/4/h',
      },
      {
        name: 'Evernote',
        img: '/images/evernote.png',
        command: 'url',
        params: 'https://classroom.google.com/u/4/h',
      },
      {
        name: 'VolumeUp',
        img: '/images/volumeup.png',
        command: 'keyboard',
        params: '4270',
      },
      {
        name: 'Youtube',
        img: '/images/youtube.png',
        command: 'url',
        params: 'https://classroom.google.com/u/4/h',
      },
      {
        name: 'Zoom',
        img: '/images/zoom.png',
        command: 'application',
        params: 'C:\\Users\\Dell\\AppData\\Roaming\\Zoom\\bin\\Zoom.exe',
      },
      {
        name: 'Mute',
        img: '/images/mute.png',
        command: 'keyboard',
        params: '4113,30',
      },
      {
        name: 'VolumeDown',
        img: '/images/volumedown.png',
        command: 'keyboard',
        params: '4269',
      },
    ],
    keyPressed: -1,
  },
  reducers: {
    setKeyName: (state, action) => {
      const index = action.payload.index;
      const name = action.payload.name;
      state.keys[index].name = name;
    },
    setKeyImage: (state, action) => {
      const index = action.payload.index;
      const img = action.payload.image;
      state.keys[index].img = img;
    },
    setKeyCommand: (state, action) => {
      const index = action.payload.index;
      const command = action.payload.command;
      state.keys[index].command = command;
    },
    setKeyParams: (state, action) => {
      const index = action.payload.index;
      const params = action.payload.params;
      state.keys[index].params = params;
    },
    setKeyPressed: (state, action) => {
      const index = action.payload.index;
      state.keyPressed = index;
    },
  }
})

// Action creators are generated for each case reducer function
export const { setKeyName, setKeyImage, setKeyCommand, setKeyParams, setKeyPressed } = streamDeckSlice.actions

export default streamDeckSlice.reducer