import { configureStore } from '@reduxjs/toolkit';
import musicReducer from './reducers/music/musicSlice';
import ledReducer from './reducers/led/ledSlice';
import ttsReducer from './reducers/tts/ttsSlice';
import deviceReducer from './reducers/device/deviceSlice';
import rgbLedReducer from './reducers/rgbLed/rgbLedSlice';
import keypadReducer from './reducers/keypad/keypadSlice';
import lcd1602Reducer from './reducers/lcd1602/lcd1602Slice';
import homeAutomationReducer from './reducers/homeAutomation/homeAutomationSlice';
import speechRecognitionReducer from './reducers/speechRecognition/speechRecognitionSlice';
import nfcReducer from './reducers/nfc/nfcSlice';
import inputsReducer from './reducers/inputs/inputsSlice';
import servoReducer from './reducers/servo/servoSlice';
import terminalReducer from './reducers/terminal/terminalSlice';
import motorsReducer from './reducers/motors/motorsSlice';
import notificationReducer from './reducers/notification/notificationSlice';
import gpioMonitorReducer from './reducers/gpioMonitor/gpioMonitorSlice';
import streamDeckReducer from './reducers/streamDeck/streamDeckSlice';
import sensorsReducer from './reducers/sensors/sensorsSlice';

export default configureStore({
  reducer: {
    music: musicReducer,
    led: ledReducer,
    tts: ttsReducer,
    device: deviceReducer,
    rgbLed: rgbLedReducer,
    keypad: keypadReducer,
    lcd1602: lcd1602Reducer,
    homeAutomation: homeAutomationReducer,
    speechRecognition: speechRecognitionReducer,
    nfc: nfcReducer,
    inputs: inputsReducer,
    servo: servoReducer,
    terminal: terminalReducer,
    motors: motorsReducer,
    notification: notificationReducer,
    gpioMonitor: gpioMonitorReducer,
    streamDeck: streamDeckReducer,
    sensors: sensorsReducer,
  }
});