import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ApplicationBar from '../../components/ApplicationBar';

function STEMProjects(props) {
  const [projects, setProjects] = useState([
    {
      title: "Voice Assistant",
      author: "Siddharth",
      authorImg: "https://avatars.githubusercontent.com/u/14032427?v=4",
      date: "02 Jan, 2023",
      desc: "A voice assistant that can be used to control your home appliances.",
    },
    {
      title: "Home Automation",
      author: "Siddharth",
      authorImg: "https://avatars.githubusercontent.com/u/14032427?v=4",
      date: "02 Jan, 2023",
      desc: "A voice assistant that can be used to control your home appliances.",
    },
  ]);

  return (
    <div className="App">
      <header className="App-header">
        <ApplicationBar back="/" title={'STEM Projects'} />
      </header>
      <Box sx={{mt:1, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {
              projects.map((project, index) => (
                <React.Fragment key={`project-${project.title}-${project.date}-${project.desc}`}>
                  <ListItem alignItems="center" >
                    <ListItemAvatar sx={{mr:2}}>
                      <Avatar alt={project.author} src={project.authorImg} sx={{width: '4rem', height: '4rem'}}/>
                    </ListItemAvatar>
                    <ListItemText
                      primary={project.title}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: 'inline', mr:1 }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {project.desc}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </React.Fragment>
              ))
            }
          </List>
        </Box>
      </Box>
    </div>
  )
}

STEMProjects.propTypes = {}

export default STEMProjects
