import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SvgIcon from '@mui/material/SvgIcon';
import ApplicationBar from '../../components/ApplicationBar';
import {useSelector, useDispatch} from 'react-redux';
import { setEnableLinearAccelerometer, setEnableGyroscope, setEnableMagnetometer, setEnableAmbientLight, setEnableGPS } from '../../reducers/sensors/sensorsSlice';
const label = { inputProps: { 'aria-label': 'Switch demo' } };

function PhoneSensors(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  const isLinearAccelerationSensorEnabled = useSelector(state => state.sensors.linearAccelerationEnabled);
  const isGyroscopeEnabled = useSelector(state => state.sensors.gyroscopeEnabled);
  const isMagnetometerEnabled = useSelector(state => state.sensors.magnetometerEnabled);
  const isAmbientLightEnabled = useSelector(state => state.sensors.ambientLightEnabled);
  const isGPSEnabled = useSelector(state => state.sensors.gpsEnabled);

  const linearAcceleration = useSelector(state => state.sensors.linearAcceleration);
  const gyroscope = useSelector(state => state.sensors.gyroscope);
  const magnetometer = useSelector(state => state.sensors.magnetometer);
  const ambientLight = useSelector(state => state.sensors.ambientLight);
  const gps = useSelector(state => state.sensors.gps);

  return (
    <div className="App">
      <header className="App-header">
        <ApplicationBar back="/" title={'Phone Sensors'} />
      </header>
      <Box sx={{ color: '#FFF', bgcolor: 'background.paper', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 1, p: 1, flexDirection: 'column', overflowY: "scroll" }}>
        <Card sx={{ display: 'flex', width: '100%', mb: 2, flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', mb: 1, ml:1, justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography component="div" variant="h5">
              Linear Accelerometer
            </Typography>
            <Switch {...label} checked={isLinearAccelerationSensorEnabled} onChange={e => dispatch(setEnableLinearAccelerometer(e.target.checked))} />
          </Box>
          <Box sx={{ display: 'flex', width: '98%', justifyContent:'space-between'}}>
            <CardMedia
              component="img"
              sx={{ width: 128, padding: 1, height: 128, pb:2, alignSelf: 'center' }}
              image="/images/accelerometer.png"
              alt="Accelerometer"
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', ml:3, pl:3, flex: '1 0 auto' }}>
              <CardContent sx={{ flex: '1 0 auto', m:0, p:0, justifyContent:'flex-start', alignItems:'center'}}>
                <Box sx={{ display: 'flex', flexDirection: 'column', pb:0, alignItems: 'start' }}>
                  <Typography component="div" variant="body1" sx={{}}>
                    X Axis
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <Button variant="outlined" sx={{mr:1}}>
                      {linearAcceleration.x}
                    </Button>
                    m/s<Box sx={{mb:0.2}}><sup>2</sup></Box>
                  </Box>
                  <Typography component="div" variant="body1" sx={{mt:1}}>
                    Y Axis
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <Button variant="outlined" sx={{mr:1}}>
                      {linearAcceleration.y}
                    </Button>
                    m/s<Box sx={{mb:0.2}}><sup>2</sup></Box>
                  </Box>
                  <Typography component="div" variant="body1" sx={{mt:1}}>
                    Z Axis
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <Button variant="outlined" sx={{mr:1}}>
                      {linearAcceleration.z}
                    </Button>
                    m/s<Box sx={{mb:0.2}}><sup>2</sup></Box>
                  </Box>
                </Box>
              </CardContent>
            </Box>
          </Box>
        </Card>
        <Card sx={{ display: 'flex', width: '100%', mb: 2, flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', mb: 1, ml:1, justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography component="div" variant="h5">
              Gyroscope
            </Typography>
            <Switch {...label} checked={isGyroscopeEnabled} onChange={e => dispatch(setEnableGyroscope(e.target.checked))} />
          </Box>
          <Box sx={{ display: 'flex', width: '98%', justifyContent:'space-between'}}>
            <CardMedia
              component="img"
              sx={{ width: 128, padding: 1, height: 128, pb:2, alignSelf: 'center' }}
              image="/images/gyroscope.png"
              alt="Gyroscope"
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', ml:3, pl:3, flex: '1 0 auto' }}>
              <CardContent sx={{ flex: '1 0 auto', m:0, p:0, justifyContent:'flex-start', alignItems:'center'}}>
                <Box sx={{ display: 'flex', flexDirection: 'column', pb:0, alignItems: 'start' }}>
                  <Typography component="div" variant="body1" sx={{}}>
                    X Axis
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <Button variant="outlined" sx={{mr:1}}>
                      {gyroscope.x}
                    </Button>
                    rad/s
                  </Box>
                  <Typography component="div" variant="body1" sx={{mt:1}}>
                    Y Axis
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <Button variant="outlined" sx={{mr:1}}>
                      {gyroscope.y}
                    </Button>
                    rad/s
                  </Box>
                  <Typography component="div" variant="body1" sx={{mt:1}}>
                    Z Axis
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <Button variant="outlined" sx={{mr:1}}>
                      {gyroscope.z}
                    </Button>
                    rad/s
                  </Box>
                </Box>
              </CardContent>
            </Box>
          </Box>
        </Card>
        <Card sx={{ display: 'flex', width: '100%', mb: 2, flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', mb: 1, ml:1, justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography component="div" variant="h5">
              Magnetometer
            </Typography>
            <Switch {...label} checked={isMagnetometerEnabled} onChange={e => dispatch(setEnableMagnetometer(e.target.checked))} />
          </Box>
          <Box sx={{ display: 'flex', width: '98%', justifyContent:'space-between'}}>
            <CardMedia
              component="img"
              sx={{ width: 128, padding: 1, height: 128, pb:2, alignSelf: 'center' }}
              image="/images/magnetometer.png"
              alt="Magnetometer"
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', ml:3, pl:3, flex: '1 0 auto' }}>
              <CardContent sx={{ flex: '1 0 auto', m:0, p:0, justifyContent:'flex-start', alignItems:'center'}}>
                <Box sx={{ display: 'flex', flexDirection: 'column', pb:0, alignItems: 'start' }}>
                  <Typography component="div" variant="body1" sx={{}}>
                    X Axis
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <Button variant="outlined" sx={{mr:1}}>
                      {magnetometer.x}
                    </Button>
                    μT
                  </Box>
                  <Typography component="div" variant="body1" sx={{mt:1}}>
                    Y Axis
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <Button variant="outlined" sx={{mr:1}}>
                      {magnetometer.y}
                    </Button>
                    μT
                  </Box>
                  <Typography component="div" variant="body1" sx={{mt:1}}>
                    Z Axis
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <Button variant="outlined" sx={{mr:1}}>
                      {magnetometer.z}
                    </Button>
                    μT
                  </Box>
                </Box>
              </CardContent>
            </Box>
          </Box>
        </Card>
        {/* <Card sx={{ display: 'flex', width: '100%', mb: 2, flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', mb: 1, ml:1, justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography component="div" variant="h5">
              Proximity Meter
            </Typography>
            <Switch {...label} checked={isAmbientLightEnabled} onChange={e => dispatch(setEnableAmbientLight(e.target.checked))} />
          </Box>
          <Box sx={{ display: 'flex', width: '98%', justifyContent:'space-between'}}>
            <CardMedia
              component="img"
              sx={{ width: 128, padding: 1, height: 128, pb:2, alignSelf: 'center' }}
              image="/images/proximity.png"
              alt="Proximity Meter"
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', ml:3, pl:3, flex: '1 0 auto' }}>
              <CardContent sx={{ flex: '1 0 auto', m:0, p:0, display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                <Box sx={{ display: 'flex', flexDirection: 'column', pb:0, alignItems: 'start' }}>
                  <Typography component="div" variant="body1" sx={{mt:1}}>
                    Distance
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <Button variant="outlined" sx={{mr:1}}>
                      {ambientLight}
                    </Button>
                    CM
                  </Box>
                </Box>
              </CardContent>
            </Box>
          </Box>
        </Card> */}
        <Card sx={{ display: 'flex', width: '100%', mb: 2, flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', mb: 1, ml:1, justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography component="div" variant="h5">
              Ambient Light
            </Typography>
            <Switch {...label} checked={isAmbientLightEnabled} onChange={e => dispatch(setEnableAmbientLight(e.target.checked))} />
          </Box>
          <Box sx={{ display: 'flex', width: '98%', justifyContent:'space-between'}}>
            <CardMedia
              component="img"
              sx={{ width: 128, padding: 1, height: 128, pb:2, alignSelf: 'center' }}
              image="/images/lightsensor.png"
              alt="Light Meter"
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', ml:3, pl:3, flex: '1 0 auto' }}>
              <CardContent sx={{ flex: '1 0 auto', m:0, p:0, display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                <Box sx={{ display: 'flex', flexDirection: 'column', pb:0, alignItems: 'start' }}>
                  <Typography component="div" variant="body1" sx={{mt:1}}>
                    Intensity
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <Button variant="outlined" sx={{mr:1}}>
                      {ambientLight}
                    </Button>
                    Lux
                  </Box>
                </Box>
              </CardContent>
            </Box>
          </Box>
        </Card>
        {/* <Card sx={{ display: 'flex', width: '100%', mb: 2, flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', mb: 1, ml:1, justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography component="div" variant="h5">
              Sound Meter
            </Typography>
            <Switch {...label} defaultChecked />
          </Box>
          <Box sx={{ display: 'flex', width: '98%', justifyContent:'space-between'}}>
            <CardMedia
              component="img"
              sx={{ width: 128, padding: 1, height: 128, pb:2, alignSelf: 'center' }}
              image="/images/soundsensor.png"
              alt="Sound Meter"
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', ml:3, pl:3, flex: '1 0 auto' }}>
              <CardContent sx={{ flex: '1 0 auto', m:0, p:0, display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                <Box sx={{ display: 'flex', flexDirection: 'column', pb:0, alignItems: 'start' }}>
                  <Typography component="div" variant="body1" sx={{mt:1}}>
                    Intensity
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <Button variant="outlined" sx={{mr:1}}>
                      5.002
                    </Button>
                    dB
                  </Box>
                </Box>
              </CardContent>
            </Box>
          </Box>
        </Card> */}
        <Card sx={{ display: 'flex', width: '100%', mb: 2, flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', mb: 1, ml:1, justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography component="div" variant="h5">
              GPS Location
            </Typography>
            <Switch {...label} checked={isGPSEnabled} onChange={e => dispatch(setEnableGPS(e.target.checked))} />
          </Box>
          <Box sx={{ display: 'flex', width: '98%', justifyContent:'space-between'}}>
            <CardMedia
              component="img"
              sx={{ width: 128, padding: 1, height: 128, pb:2, alignSelf: 'center' }}
              image="/images/location.png"
              alt="Location"
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', ml:3, pl:3, flex: '1 0 auto' }}>
              <CardContent sx={{ flex: '1 0 auto', m:0, p:0, display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                <Box sx={{ display: 'flex', flexDirection: 'column', pb:0, alignItems: 'start' }}>
                  <Typography component="div" variant="body1" sx={{mt:1}}>
                    Longitude
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <Button variant="outlined" sx={{mr:1}}>
                      {gps.longitude}
                    </Button>
                  </Box>
                  <Typography component="div" variant="body1" sx={{mt:1}}>
                    Latitude
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <Button variant="outlined" sx={{mr:1}}>
                      {gps.latitude}
                    </Button>
                  </Box>
                  <Typography component="div" variant="body1" sx={{mt:1}}>
                    Altitude
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <Button variant="outlined" sx={{mr:1}}>
                      {gps.altitude}
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Box>
          </Box>
        </Card>
        {/* <Card sx={{ display: 'flex', width: '100%', mb: 2, flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', mb: 1, ml:1, justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography component="div" variant="h5">
              Temperature
            </Typography>
            <Switch {...label} defaultChecked />
          </Box>
          <Box sx={{ display: 'flex', width: '98%', justifyContent:'space-between'}}>
            <CardMedia
              component="img"
              sx={{ width: 128, padding: 1, height: 128, pb:2, alignSelf: 'center' }}
              image="/images/temperature.png"
              alt="Temperature"
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', ml:3, pl:3, flex: '1 0 auto' }}>
              <CardContent sx={{ flex: '1 0 auto', m:0, p:0, display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                <Box sx={{ display: 'flex', flexDirection: 'column', pb:0, alignItems: 'start' }}>
                  <Typography component="div" variant="body1" sx={{mt:1}}>
                    Celcius
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <Button variant="outlined" sx={{mr:1}}>
                      25.32
                    </Button>
                    °C
                  </Box>
                  <Typography component="div" variant="body1" sx={{mt:1}}>
                    Fahrenheit
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <Button variant="outlined" sx={{mr:1}}>
                      25.32
                    </Button>
                    °F
                  </Box>
                </Box>
              </CardContent>
            </Box>
          </Box>
        </Card>
        <Card sx={{ display: 'flex', width: '100%', mb: 2, flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', mb: 1, ml:1, justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography component="div" variant="h5">
              Barometer
            </Typography>
            <Switch {...label} defaultChecked />
          </Box>
          <Box sx={{ display: 'flex', width: '98%', justifyContent:'space-between'}}>
            <CardMedia
              component="img"
              sx={{ width: 128, padding: 1, height: 128, pb:2, alignSelf: 'center' }}
              image="/images/barometer.png"
              alt="Barometer"
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', ml:3, pl:3, flex: '1 0 auto' }}>
              <CardContent sx={{ flex: '1 0 auto', m:0, p:0, display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                <Box sx={{ display: 'flex', flexDirection: 'column', pb:0, alignItems: 'start' }}>
                <Typography component="div" variant="body1" sx={{mt:1}}>
                    Altitude
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <Button variant="outlined" sx={{mr:1}}>
                      3532
                    </Button>
                    ft
                  </Box>
                  <Typography component="div" variant="body1" sx={{mt:1}}>
                    Pressure (Sea Level)
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <Button variant="outlined" sx={{mr:1}}>
                      25.32
                    </Button>
                    hPa
                  </Box>
                </Box>
              </CardContent>
            </Box>
          </Box>
        </Card> */}
      </Box>
    </div>
  )
}

PhoneSensors.propTypes = {}

export default PhoneSensors
