import React from 'react';
import PropTypes from 'prop-types';
import LCD from '../../components/LCD1602';
import ApplicationBar from '../../components/ApplicationBar';

function LCD1602(props) {
  return (
    <div className="App">
      <header className="App-header">
        <ApplicationBar back="/emulators" />
      </header>
      <LCD/>
    </div>
  )
}

LCD1602.propTypes = {}

export default LCD1602
