import '../app.css';
import Box from '@mui/material/Box';
import ApplicationBar from '../../components/ApplicationBar';
import FeatureList from '../../components/FeatureList';

function Main() {
  return (
    <div className="App">
      <header className="App-header">
        <ApplicationBar back="" />
      </header>
      <Box sx={{backgroundColor: "#FFF"}}>
        <FeatureList />
      </Box>
    </div>
  );
}

export default Main;
