import { createSlice } from '@reduxjs/toolkit'

export const motorsSlice = createSlice({
  name: 'motors',
  initialState: {
    motor1Speed: 50,
    motor1Direction: 'cw',
    motor2Speed: 50,
    motor2Direction: 'cw',
    motor3Speed: 50,
    motor3Direction: 'cw',
    motor4Speed: 50,
    motor4Direction: 'cw',
  },
  reducers: {
    setMotor1Speed: (state, action) => {
      state.motor1Speed = action.payload;
    },
    setMotor1Direction: (state, action) => {
      if(action.payload !== 'cw' && action.payload !== 'ccw') {
        return;
      }
      state.motor1Direction = action.payload;
    },
    setMotor2Speed: (state, action) => {
      state.motor2Speed = action.payload;
    },
    setMotor2Direction: (state, action) => {
      if(action.payload !== 'cw' && action.payload !== 'ccw') {
        return;
      }
      state.motor2Direction = action.payload;
    },
    setMotor3Speed: (state, action) => {
      state.motor3Speed = action.payload;
    },
    setMotor3Direction: (state, action) => {
      if(action.payload !== 'cw' && action.payload !== 'ccw') {
        return;
      }
      state.motor3Direction = action.payload;
    },
    setMotor4Speed: (state, action) => {
      state.motor4Speed = action.payload;
    },
    setMotor4Direction: (state, action) => {
      if(action.payload !== 'cw' && action.payload !== 'ccw') {
        return;
      }
      state.motor4Direction = action.payload;
    }
  }
})

// Action creators are generated for each case reducer function
export const { setMotor1Speed, setMotor1Direction, setMotor2Speed, setMotor2Direction, setMotor3Speed, setMotor3Direction, setMotor4Speed, setMotor4Direction } = motorsSlice.actions

export default motorsSlice.reducer