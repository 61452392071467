import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import StorageIcon from '@mui/icons-material/Storage';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import DatasetIcon from '@mui/icons-material/Dataset';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Divider from '@mui/material/Divider';
import ApplicationBar from '../../components/ApplicationBar';
import { Typography } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';

const rows = [
  {column: 'Column 1', value: 'Value 1'},
  {column: 'Column 2', value: 'Value 2'}
];

function DataLogging(props) {
  const [tab, setTab] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="App">
      <header className="App-header">
        <ApplicationBar back="/iot" title={'Data Logging'} />
      </header>
      <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', overflowY: 'scroll'}}>
        {
          (tab === 0) && (
            <>
              <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Status:"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Connnected
                        </Typography>
                        {""}
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider variant="middle" component="li" />
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="File Name:"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          temperature.csv
                        </Typography>
                        {""}
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider variant="middle" component="li" />
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Last Received:"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          00:05:23 AM
                        </Typography>
                        {''}
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider variant="middle" component="li" />
              </List>
              <Box sx={{mt: 2, ml:2, mr:2, width: '90%'}}>
                <Typography variant="subtitle1" component="div" sx={{mt:1, mb:1, textAlign: 'left'}}> Last record </Typography>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Column</TableCell>
                        <TableCell align="left">Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          key={row.column}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {row.column}
                          </TableCell>
                          <TableCell align="left">{row.value}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Button variant="contained" startIcon={<CloseIcon />} sx={{mt:2}}>
                  Close File
                </Button>
              </Box>
            </>
          )
        }
        {
          (tab !== 0) && (
            <>
              <List sx={{ width: '100%', bgcolor: 'background.paper'}}>
                <ListItem alignItems="center" secondaryAction={
                    <IconButton edge="end" aria-label="menu" onClick={handleClick}>
                      <MoreVertIcon />
                    </IconButton>
                  }>
                  <ListItemIcon>
                    <StorageIcon />
                  </ListItemIcon>
                  <ListItemText primary="temperature.csv" />
                </ListItem>
                <ListItem alignItems="center" secondaryAction={
                    <IconButton edge="end" aria-label="menu" onClick={handleClick}>
                      <MoreVertIcon />
                    </IconButton>
                  }>
                  <ListItemIcon>
                    <StorageIcon />
                  </ListItemIcon>
                  <ListItemText primary="humidity.csv"/>
                </ListItem>
                <ListItem alignItems="center" secondaryAction={
                    <IconButton edge="end" aria-label="menu" onClick={handleClick}>
                      <MoreVertIcon />
                    </IconButton>
                  }>
                  <ListItemIcon>
                    <StorageIcon />
                  </ListItemIcon>
                  <ListItemText primary="motion.csv" />
                </ListItem>
              </List>
              <Menu
                anchorEl={anchorEl}
                id="file-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    minWidth: '140px',
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 0,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    }
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem>
                  <ListItemIcon>
                    <LaunchIcon fontSize="small" />
                  </ListItemIcon>
                  Open
                </MenuItem>
                <MenuItem>
                  <ListItemIcon>
                    <ShareIcon fontSize="small" />
                  </ListItemIcon>
                  Share
                </MenuItem>
                <MenuItem>
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  Delete
                </MenuItem>
              </Menu>
            </>
          )
        }
      </Box>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%' }} elevation={3}>
        <BottomNavigation
          showLabels
          value={tab}
          onChange={(event, newValue) => {
            setTab(newValue);
          }}
        >
          <BottomNavigationAction label="Data" icon={<DatasetIcon fontSize="large"/>} />
          <BottomNavigationAction label="Files" icon={<ListAltIcon fontSize="large"/>} />
        </BottomNavigation>
      </Paper>
    </div>
  )
}

DataLogging.propTypes = {}

export default DataLogging
