import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import ApplicationBar from '../../components/ApplicationBar';
import "./switch.css";
import {useDispatch, useSelector} from 'react-redux';
import {setSwitchState} from '../../reducers/homeAutomation/homeAutomationSlice';

function HomeAutomation(props) {
  const dispatch = useDispatch();
  const switches = useSelector(state => state.homeAutomation.switches);

  const handleSwitchChange = (index, state) => {
    dispatch(setSwitchState({index: index, state: state}));
  };

  return (
    <div className="App">
      <header className="App-header">
        <ApplicationBar back="/" title={'Home Automation'} />
      </header>
      <Box sx={{ width: '100%', bgcolor: 'background.paper', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'center', overflowY: 'scroll' }}>
        {
          switches.map((elcSwitch, index) => (
            <Card key={`device-switch-${index}`} sx={{ ml: 1, mr: 1, mt: 2, mb: 2, zIndex: 0, justifyContent: 'space-between', display: 'flex', flexDirection: 'column', minWidth: '136px', maxWidth: '136px'}}>
              <CardHeader title={elcSwitch.name} sx={{pb:0}}/>
              <CardContent sx={{pb:0,pt:0}}>
                <Box className="switch electric">
                  <input type="checkbox" checked={!elcSwitch.state} onChange={e => handleSwitchChange(index, !e.target.checked)}/>
                  <label></label>
                </Box>
              </CardContent>
            </Card>
          ))
        }
      </Box>
    </div>
  )
}

HomeAutomation.propTypes = {}

export default HomeAutomation
