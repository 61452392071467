import { createSlice } from '@reduxjs/toolkit'

export const servoSlice = createSlice({
  name: 'servo',
  initialState: {
    servo0 : 90,
    servo1 : 90,
    servo2 : 90,
    servo3 : 90,
    servo4 : 90,
    servo5 : 90,
    servo6 : 90,
    servo7 : 90,
    servo8 : 90,
    servo9 : 90,
    servo10 : 90,
    servo11 : 90,
    servo12 : 90,
    servo13 : 90,
    servo14 : 90,
    servo15 : 90,
  },
  reducers: {
    setServoAngle: (state, action) => {
      const servo = parseInt(action.payload.servo, 10);
      const angle = parseInt(action.payload.angle, 10);
      if(servo >= 0 && servo < 16) {
        if(angle >= 0 && angle <= 180) {
          switch(servo) {
            case 0:
              state.servo0 = angle;
              break;
            case 1:
              state.servo1 = angle;
              break;
            case 2:
              state.servo2 = angle;
              break;
            case 3:
              state.servo3 = angle;
              break;
            case 4:
              state.servo4 = angle;
              break;
            case 5:
              state.servo5 = angle;
              break;
            case 6:
              state.servo6 = angle;
              break;
            case 7:
              state.servo7 = angle;
              break;
            case 8:
              state.servo8 = angle;
              break;
            case 9:
              state.servo9 = angle;
              break;
            case 10:
              state.servo10 = angle;
              break;
            case 11:
              state.servo11 = angle;
              break;
            case 12:
              state.servo12 = angle;
              break;
            case 13:
              state.servo13 = angle;
              break;
            case 14:
              state.servo14 = angle;
              break;
            case 15:
              state.servo15 = angle;
              break;
            default:
              break;
          }
        }
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const { setServoAngle } = servoSlice.actions

export default servoSlice.reducer