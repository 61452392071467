import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore';
import {getAuth} from 'firebase/auth';
import {getMessaging, getToken, onMessage} from 'firebase/messaging';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyDyeL3d7xtSgvN6qkmJ5TSdIbzQBge4O6M',
    authDomain: 'codeskool-cc.firebaseapp.com',
    projectId: 'codeskool-cc',
    storageBucket: 'codeskool-cc.appspot.com',
    messagingSenderId: '147568153083',
    appId: '1:147568153083:web:cc5408a7e94a96a8ffbb5e',
    measurementId: 'G-YPDHWE2FPQ'
};

const app = initializeApp(firebaseConfig);
// init firestore
const db = getFirestore();
const auth = getAuth();

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

const getMessagingToken = () => new Promise((resolve, reject) => {
    const publicKey = `BDDeGCmSKY5RRGzBmwKIwhaFttBaaqkAeEbGWXrTI8rC6frTB5H1MEmWKslfVeAax5tgdRFjnSC3GScw1Hq782w`;
    try {
        return getToken(messaging, {vapidKey: publicKey}).then(currentToken => {
            if (currentToken) {
                resolve(currentToken);
            }
            reject(new Error('No registration token available. Request permission to generate one.'));
        });
    } catch (error) {
        reject(error);
    }
});

export {db, auth, messaging, getMessagingToken, onMessage};
