import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.css';

function JogDial({id, onChange}) {
  const [dial, setDial] = useState(null);
  useEffect(() => {
    if(!dial) {
      const d = window.JogDial(document.getElementById(id), {
        debug : false,
        touchMode : 'knob',  // knob | wheel
        knobSize : '70px',
        wheelSize : '200px',
        zIndex : 9999,
        degreeStartAt : 0,
        minDegree : null,  // (null) infinity
        maxDegree : null   // (null) infinity
      });
      d.on("mousemove", function(event){ onChange(event.target.degree); })
       .on("mouseup", function(event){ onChange(event.target.degree); });
      setDial(d);
    }
  }, [dial, id, onChange]);

  return (
    <div id={id} className="knobComp"></div>
  )
}

JogDial.propTypes = {}

export default JogDial
