import React from 'react';
import { Outlet, Link } from "react-router-dom";
import Paper from '@mui/material/Paper';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useMediaQuery, useTheme } from '@mui/material';

export default function FeatureList() {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <ImageList sx={{ my:1, mx:1, backgroundColor: "#FFF" }} cols={matchDownMd ? 2 : 4} gap={8}>
      {itemData.map((item) => (
        <Paper key={item.img} elevation={3}>
          <ImageListItem sx={{borderWidth: 1, borderStyle: "solid", borderColor: "#CCC", padding:1}}>
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
            <Link to={item.slug}>
              <ImageListItemBar
                title={item.title}
                subtitle={item.author}
                actionIcon={
                  <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    aria-label={`info about ${item.title}`}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                }
                sx={{fontSize: '2rem', fontWeight: 700}}
              />
            </Link>
          </ImageListItem>
        </Paper>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: '/images/led.png',
    title: 'LED Brightness Control',
    slug: 'led',
    author: '',
  },
  {
    img: '/images/inputs.png',
    title: 'Input Controls',
    slug: 'inputs',
    author: '',
  },
  {
    img: '/images/motor.png',
    title: 'Motor Control',
    slug: 'motor',
    author: '',
  },
  {
    img: '/images/servo.png',
    title: 'Servo Control',
    slug: 'servo',
    author: '',
  },
  {
    img: '/images/chip.png',
    title: 'Pins State Monitor',
    slug: 'monitor',
    author: '',
  },
  {
    img: '/images/terminal.png',
    title: 'Serial Communication',
    slug: 'serial-communication',
    author: '',
  },
  {
    img: '/images/phone-sensors.png',
    title: 'Phone Sensors',
    slug: 'sensors',
    author: '',
  },
  {
    img: '/images/camera.png',
    title: 'Camera',
    slug: 'camera',
    author: '',
  },
  {
    img: '/images/music.png',
    title: 'Music',
    slug: 'music',
    author: '',
  },
  {
    img: '/images/rgb-strip.png',
    title: 'RGB LED Strip',
    slug: 'rgb-strip',
    author: '',
  },
  {
    img: '/images/gamepad.png',
    title: 'Gamepad',
    slug: 'gamepad',
    author: '',
  },
  {
    img: '/images/nfc.png',
    title: 'NFC',
    slug: 'nfc',
    author: '',
  },
  {
    img: '/images/tts.png',
    title: 'Text to Speech',
    slug: 'tts',
    author: '',
  },
  {
    img: '/images/voice-assistant.png',
    title: 'Speech Recognition',
    slug: 'speech-recognition',
    author: '',
  },
  {
    img: '/images/home-automation.png',
    title: 'Home Automation',
    slug: 'home-automation',
    author: '',
  },
  {
    img: '/images/iot.png',
    title: 'IoT',
    slug: 'iot',
    author: '',
  },
  {
    img: '/images/emulator.png',
    title: 'Hardware Emulators',
    slug: 'emulators',
    author: '',
  },
  {
    img: '/images/oscilloscope.png',
    title: 'Oscilloscope',
    slug: 'oscilloscope',
    author: '',
  },
  {
    img: '/images/editor.png',
    title: 'Code Editor',
    slug: 'editor',
    author: '',
  },
  {
    img: '/images/stem.png',
    title: 'STEM Projects',
    slug: 'projects',
    author: '',
  },
];
