import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import SvgIcon from '@mui/material/SvgIcon';
import ApplicationBar from '../../components/ApplicationBar';
import { useSelector, useDispatch } from 'react-redux';
import { setMotor1Speed, setMotor1Direction, setMotor2Speed, setMotor2Direction, setMotor3Speed, setMotor3Direction, setMotor4Speed, setMotor4Direction } from '../../reducers/motors/motorsSlice';

function TortoiseIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M19.31,5.6C18.09,5.56 16.88,6.5 16.5,8C16,10 16,10 15,11C13,13 10,14 4,15C3,15.16 2.5,15.5 2,16C4,16 6,16 4.5,17.5L3,19H6L8,17C10,18 11.33,18 13.33,17L14,19H17L16,16C16,16 17,12 18,11C19,10 19,11 20,11C21,11 22,10 22,8.5C22,8 22,7 20.5,6C20.15,5.76 19.74,5.62 19.31,5.6M9,6A6,6 0 0,0 3,12C3,12.6 3.13,13.08 3.23,13.6C9.15,12.62 12.29,11.59 13.93,9.94L14.43,9.44C13.44,7.34 11.32,6 9,6Z" />
    </SvgIcon>
  );
}

function RabbitIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M18.05,21L15.32,16.26C15.32,14.53 14.25,13.42 12.95,13.42C12.05,13.42 11.27,13.92 10.87,14.66C11.2,14.47 11.59,14.37 12,14.37C13.3,14.37 14.36,15.43 14.36,16.73C14.36,18.04 13.31,19.11 12,19.11H15.3V21H6.79C6.55,21 6.3,20.91 6.12,20.72C5.75,20.35 5.75,19.75 6.12,19.38V19.38L6.62,18.88C6.28,18.73 6,18.5 5.72,18.26C5.5,18.76 5,19.11 4.42,19.11C3.64,19.11 3,18.47 3,17.68C3,16.9 3.64,16.26 4.42,16.26L4.89,16.34V14.37C4.89,11.75 7,9.63 9.63,9.63H9.65C11.77,9.64 13.42,10.47 13.42,9.16C13.42,8.23 13.62,7.86 13.96,7.34C13.23,7 12.4,6.79 11.53,6.79C11,6.79 10.58,6.37 10.58,5.84C10.58,5.41 10.86,5.05 11.25,4.93L10.58,4.89C10.06,4.89 9.63,4.47 9.63,3.95C9.63,3.42 10.06,3 10.58,3H11.53C13.63,3 15.47,4.15 16.46,5.85L16.74,5.84C17.45,5.84 18.11,6.07 18.65,6.45L19.1,6.83C21.27,8.78 21,10.1 21,10.11C21,11.39 19.94,12.44 18.65,12.44L18.16,12.39V12.47C18.16,13.58 17.68,14.57 16.93,15.27L20.24,21H18.05M18.16,7.74C17.63,7.74 17.21,8.16 17.21,8.68C17.21,9.21 17.63,9.63 18.16,9.63C18.68,9.63 19.11,9.21 19.11,8.68C19.11,8.16 18.68,7.74 18.16,7.74Z" />
    </SvgIcon>
  );
}

function MotorControl(props) {
  const dispatch = useDispatch();
  const motor1Speed = useSelector(state => state.motors.motor1Speed);
  const motor1Direction = useSelector(state => state.motors.motor1Direction);
  const motor2Speed = useSelector(state => state.motors.motor2Speed);
  const motor2Direction = useSelector(state => state.motors.motor2Direction);
  const motor3Speed = useSelector(state => state.motors.motor3Speed);
  const motor3Direction = useSelector(state => state.motors.motor3Direction);
  const motor4Speed = useSelector(state => state.motors.motor4Speed);
  const motor4Direction = useSelector(state => state.motors.motor4Direction);

  const motors = [0, 1, 2, 3];
  const speed = [motor1Speed, motor2Speed, motor3Speed, motor4Speed];
  const direction = [motor1Direction, motor2Direction, motor3Direction, motor4Direction];

  const handleMotorSpeedChange = (event, newValue, motor) => {
    if(motor === 0) {
      dispatch(setMotor1Speed(newValue));
    } else if(motor === 1) {
      dispatch(setMotor2Speed(newValue));
    } else if(motor === 2) {
      dispatch(setMotor3Speed(newValue));
    } else if(motor === 3) {
      dispatch(setMotor4Speed(newValue));
    }
  };

  const handleMotorDirectionChange = (dir, motor) => {
    if(!dir) {
      return;
    }
    if (motor === 0) {
      dispatch(setMotor1Direction(dir));
    } else if (motor === 1) {
      dispatch(setMotor2Direction(dir));
    } else if (motor === 2) {
      dispatch(setMotor3Direction(dir));
    } else if (motor === 3) {
      dispatch(setMotor4Direction(dir));
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <ApplicationBar back="/" title={'Motor Control'} />
      </header>
      <Box sx={{ color: '#FFF', bgcolor: 'background.paper', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 2, flexDirection: 'column' }}>
        {
          motors.map((index) => (
            <Card key={`motor-index${index}`} sx={{ minWidth: '100%', marginBottom: 2 }}>
              <CardContent>
                <Stack direction="row" spacing={1} sx={{alignItems:"center", marginTop: 2}}>
                  <TortoiseIcon/>
                  <Slider value={speed[index]} aria-label="Default" valueLabelDisplay="auto" onChange={(event, newValue) => handleMotorSpeedChange(event, newValue, index)} />
                  <RabbitIcon/>
                </Stack>
              </CardContent>
              <CardActions>
                <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", width: '100%'}}>
                  <Typography sx={{ mb: 1.5, fontWeight:600, fontSize: '1.1rem', ml: 1.5, marginTop:"auto", marginBottom:"auto"}} align="center" color="primary.dark">
                    {`Motor ${index+1}`}
                  </Typography>
                  <ToggleButtonGroup
                    color="primary"
                    value={direction[index]}
                    exclusive
                    onChange={e => handleMotorDirectionChange(e.target.value, index)}
                    aria-label="Direction"
                    sx={{ mr: 1.5}}
                  >
                    <ToggleButton value="ccw"><RotateLeftIcon/>CCW</ToggleButton>
                    <ToggleButton value="cw"><RotateRightIcon/>CW&nbsp;&nbsp;</ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </CardActions>
            </Card>
          ))
        }
      </Box>
    </div>
  )
}

MotorControl.propTypes = {}

export default MotorControl
