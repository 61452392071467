import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ApplicationBar from '../../components/ApplicationBar';
import { setKeyPressed } from '../../reducers/keypad/keypadSlice';
import { useDispatch } from 'react-redux';

const keyMaps = [
  {key: "1", keyCode: 1, x: 21, y: 89, width: 77, height: 32},
  {key: "2", keyCode: 2, x: 85, y: 90, width: 144, height: 32},
  {key: "3", keyCode: 3, x: 152, y: 90, width: 209, height: 32},
  {key: "A", keyCode: 10, x: 274, y: 30, width: 217, height: 88},
  {key: "4", keyCode: 4, x: 79, y: 100, width: 21, height: 159},
  {key: "5", keyCode: 5, x: 143, y: 102, width: 85, height: 159},
  {key: "6", keyCode: 6, x: 211, y: 100, width: 151, height: 159},
  {key: "B", keyCode: 11, x: 275, y: 100, width: 218, height: 160},
  {key: "7", keyCode: 7, x: 79, y: 169, width: 18, height: 228},
  {key: "8", keyCode: 8, x: 144, y: 170, width: 85, height: 229},
  {key: "9", keyCode: 9, x: 209, y: 170, width: 151, height: 230},
  {key: "C", keyCode: 12, x: 275, y: 170, width: 217, height: 228},
  {key: "*", keyCode: 14, x: 20, y: 299, width: 79, height: 240},
  {key: "0", keyCode: 0, x: 144, y: 240, width: 85, height: 298},
  {key: "#", keyCode: 15, x: 210, y: 240, width: 151, height: 298},
  {key: "D", keyCode: 13, x: 276, y: 240, width: 217, height: 299}
];

const MembraneKeypad = props => {
  const dispatch = useDispatch();
  const imageRef = useRef(null);
  const [imageRatio, setImageRatio] = useState(1);

  const onWindowResize = (e) => {
    const imageWidth = imageRef.current.clientWidth;
    setImageRatio(imageWidth/295);
  };

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    setTimeout(() => {
      onWindowResize();
    }, 500);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  const onKeyPress = (e, keyCode) => {
    e.preventDefault();
    dispatch(setKeyPressed(keyCode));
    setTimeout(() => {
      dispatch(setKeyPressed(-1));
    }, 500);
    navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;
    if(navigator.vibrate) {
      navigator.vibrate(100);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <ApplicationBar back="/emulators" />
      </header>
      <Box sx={{mt:5, mx: 2}}>
        <img ref={imageRef} src="/images/membrane-keypad.png" alt="Membrane Keypad" style={{width: "100%"}} useMap="#keypad-map"/>
        <map name="keypad-map">
          {
            keyMaps.map((keyMap, index) => {
              return (
                <area key={`keypad-${index}`}
                  shape="rect"
                  target="_self"
                  alt={keyMap.key}
                  title={keyMap.key}
                  coords={`${keyMap.x * imageRatio},${keyMap.y * imageRatio},${keyMap.width * imageRatio},${keyMap.height * imageRatio}`}
                  href="#"
                  onClick={(e) => onKeyPress(e, keyMap.keyCode)} />
              );
            })
          }
      </map>
      </Box>
    </div>
  )
}

MembraneKeypad.propTypes = {}

export default MembraneKeypad