import { createSlice } from '@reduxjs/toolkit'

const sendNotification = (args) => {
  const url = 'https://us-central1-codeskool-cc.cloudfunctions.net/sendNotification';
  const data = {
    token: args.token,
    title: args.title,
    description: args.description,
    thumbnailUrl: args.thumbnailUrl,
    path: args.notificationPath
  };
  if (data.token.length <= 0 || data.title.length <= 0 || data.description.length <= 0) {
    return;
  }
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(res => res.text()).then(message => message);
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notifications: [
      // {
      //   title: 'Fire Alarm',
      //   img: 'https://cdn-icons-png.flaticon.com/512/785/785116.png',
      //   timeStamp: {year: 2022, month: 10, day: 17, hour: 10, minute: 10, second: 10},
      //   desc: 'Fire in the kitchen',
      // },
      // {
      //   title: 'Temperature Alert',
      //   img: 'https://cdn-icons-png.flaticon.com/512/3815/3815449.png',
      //   timeStamp: {year: 2022, month: 10, day: 15, hour: 10, minute: 10, second: 10},
      //   desc: 'Temperature is too high',
      // },
      // {
      //   title: 'Soil Moisture Alert',
      //   img: 'https://cdn-icons-png.flaticon.com/512/6634/6634686.png',
      //   timeStamp: {year: 2022, month: 9, day: 10, hour: 10, minute: 10, second: 10},
      //   desc: 'Soil moisture is too low',
      // }
    ],
  },
  reducers: {
    addNewNotification: (state, action) => {
      const d = new Date();
      const timeStamp = { year: d.getFullYear(), month: d.getMonth(), day: d.getDate(), hours: d.getHours(), minutes: d.getMinutes(), second: d.getSeconds(), milliseconds: d.getMilliseconds() };
      const notificationData = {
        token: action.payload.token || '',
        title: action.payload.title || '',
        description: action.payload.desc || '',
        thumbnailUrl: action.payload.img || '',
        notificationPath: action.payload.url || ''
      };
      sendNotification(notificationData);
      const notification = { ...action.payload, timeStamp };
      state.notifications = [notification, ...state.notifications];
    },
    clearNotifications: (state) => {
      state.notifications = [];
    },
  }
})

// Action creators are generated for each case reducer function
export const { addNewNotification, clearNotifications  } = notificationSlice.actions

export default notificationSlice.reducer