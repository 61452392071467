import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { playNoteByName } from '../../reducers/music/musicSlice';
import { clear, print, setCursor } from '../../reducers/lcd1602/lcd1602Slice';
import { addSwitch, resetElectricSwitches } from '../../reducers/homeAutomation/homeAutomationSlice';
import { addIncomingMessage, clearNewTerminalMessage } from '../../reducers/terminal/terminalSlice';
import { addNewNotification } from '../../reducers/notification/notificationSlice';
import { setDigitalPinValue, setAnalogPinValue } from '../../reducers/gpioMonitor/gpioMonitorSlice';
import { setLinearAccelerometerReading, setGyroscopeReading, setMagnetometerReading, setAmbientLightReading, setGPSReading } from '../../reducers/sensors/sensorsSlice';

let linearAccelerationSensor = null;
let gyroscopeSensor = null;
let magnetometerSensor = null;
let ambientLightSensor = null;
let gpsWatchID = null;

const App = (props) => {
  const dispatch = useDispatch();
  const [ws, setWS] = useState(null);
  const [ttsVoices, setTTSVoices] = useState([]);
  const deviceIPAddress = useSelector(state => state.device.ipAddress);
  const ttsVoice = useSelector(state => state.tts.voiceName);
  const ttsPitch = useSelector(state => state.tts.pitch);
  const ttsRate = useSelector(state => state.tts.rate);
  const ttsVolume = useSelector(state => state.tts.volume);
  const ttsEnabled = useSelector(state => state.tts.enabled);
  const pwm1 = useSelector(state => state.led.brightness1);
  const pwm2 = useSelector(state => state.led.brightness2);
  const pwm3 = useSelector(state => state.led.brightness3);
  const pwm4 = useSelector(state => state.led.brightness4);
  const pwm5 = useSelector(state => state.led.brightness5);
  const pwm6 = useSelector(state => state.led.brightness6);
  const pwm7 = useSelector(state => state.led.brightness7);
  const pwm8 = useSelector(state => state.led.brightness8);
  const pwm9 = useSelector(state => state.led.brightness9);
  const pwm10 = useSelector(state => state.led.brightness10);
  const rgbLedIndex = useSelector(state => state.rgbLed.led);
  const rgbLedColor = useSelector(state => state.rgbLed.color);
  const rgbLedBrightness = useSelector(state => state.rgbLed.brightness);
  const keyPressed = useSelector(state => state.keypad.keyPressed);
  const elecSwitches = useSelector(state => state.homeAutomation.switches);
  const lastModifiedElecSwitch = useSelector(state => state.homeAutomation.lastModifiedSwitch);
  const speechRecognized = useSelector(state => state.speechRecognition.speechRecognized);
  const nfcRecordType = useSelector(state => state.nfc.recordType);
  const nfcData = useSelector(state => state.nfc.data);
  const nfcSerialNumber = useSelector(state => state.nfc.serialNumber);
  const slideSwitch1 = useSelector(state => state.inputs.switch1);
  const slideSwitch2 = useSelector(state => state.inputs.switch2);
  const slideSwitch3 = useSelector(state => state.inputs.switch3);
  const toggleSwitch1 = useSelector(state => state.inputs.button1);
  const toggleSwitch2 = useSelector(state => state.inputs.button2);
  const toggleSwitch3 = useSelector(state => state.inputs.button3);
  const toggleSwitch4 = useSelector(state => state.inputs.button4);
  const toggleSwitch5 = useSelector(state => state.inputs.button5);
  const dial1 = useSelector(state => state.inputs.dial1);
  const dial2 = useSelector(state => state.inputs.dial2);
  const servo0Angle = useSelector(state => state.servo.servo0);
  const servo1Angle = useSelector(state => state.servo.servo1);
  const servo2Angle = useSelector(state => state.servo.servo2);
  const servo3Angle = useSelector(state => state.servo.servo3);
  const servo4Angle = useSelector(state => state.servo.servo4);
  const servo5Angle = useSelector(state => state.servo.servo5);
  const servo6Angle = useSelector(state => state.servo.servo6);
  const servo7Angle = useSelector(state => state.servo.servo7);
  const servo8Angle = useSelector(state => state.servo.servo8);
  const servo9Angle = useSelector(state => state.servo.servo9);
  const servo10Angle = useSelector(state => state.servo.servo10);
  const servo11Angle = useSelector(state => state.servo.servo11);
  const servo12Angle = useSelector(state => state.servo.servo12);
  const servo13Angle = useSelector(state => state.servo.servo13);
  const servo14Angle = useSelector(state => state.servo.servo14);
  const servo15Angle = useSelector(state => state.servo.servo15);
  const terminalMessage = useSelector(state => state.terminal.newMessage);
  const motor1Speed = useSelector(state => state.motors.motor1Speed);
  const motor2Speed = useSelector(state => state.motors.motor2Speed);
  const motor3Speed = useSelector(state => state.motors.motor3Speed);
  const motor4Speed = useSelector(state => state.motors.motor4Speed);
  const motor1Direction = useSelector(state => state.motors.motor1Direction);
  const motor2Direction = useSelector(state => state.motors.motor2Direction);
  const motor3Direction = useSelector(state => state.motors.motor3Direction);
  const motor4Direction = useSelector(state => state.motors.motor4Direction);
  const streamDeckKeyPressed = useSelector(state => state.streamDeck.keyPressed);
  const streamDeckKeys = useSelector((state) => state.streamDeck.keys);
  const isLinearAccelerationSensorEnabled = useSelector(state => state.sensors.linearAccelerationEnabled);
  const isGyroscopeSensorEnabled = useSelector(state => state.sensors.gyroscopeEnabled);
  const isMagnetometerSensorEnabled = useSelector(state => state.sensors.magnetometerEnabled);
  const isAmbientLightEnabled = useSelector(state => state.sensors.ambientLightEnabled);
  const isGPSEnabled = useSelector(state => state.sensors.gpsEnabled);

  const [textToSpeak, setTextToSpeak] = useState('');
  const [musicNote, setMusicNote] = useState('');

  const getTTSVoices = useCallback(() => {
    if ('speechSynthesis' in window) {
      const voices = window.speechSynthesis.getVoices();
      if (voices.length > 0) {
        setTTSVoices(voices);
      } else {
        setTimeout(getTTSVoices, 2000);
      }
    }
  }, []);

  useEffect(() => {
    if(ttsVoices.length === 0) {
      getTTSVoices();
    }
  }, [getTTSVoices, ttsVoices]);

  const onWebSocketOpen = () => {
    console.log('Connected to websocket server');
  };

  const onWebSocketClose = () => {
    console.log('Disconnected from websocket server');
  };

  const onWebSocketError = (error) => {
    console.log('Error: ' + error);
  };

  const processMessage = useCallback((message) => {
    switch (message.command) {
      case 'playNote':
        setMusicNote(message.value);
        break;
      case 'speakText':
        setTextToSpeak(message.value);
        break;
      case 'LCD1602Clear':
        dispatch(clear());
        break;
      case 'LCD1602SetCursor':
        dispatch(setCursor(JSON.parse(message.value)));
        break;
      case 'LCD1602Print':
        dispatch(print(message.value));
        break;
      case 'ResetElectricSwitches':
        dispatch(resetElectricSwitches());
        break;
      case 'AddElectricSwitch':
        dispatch(addSwitch(JSON.parse(message.value)));
        break;
      case 'Terminal':
        const msg = message.value.trim();
        if(msg.length > 0) {
          dispatch(addIncomingMessage(msg));
        }
        break;
      case 'Notification':
        dispatch(addNewNotification(JSON.parse(message.value)));
        break;
      case 'AnalogPinChanged':
        {
          const data = JSON.parse(message.value);
          dispatch(setAnalogPinValue(data));
        }
        break;
      case 'DigitalPinChanged':
        {
          const data = JSON.parse(message.value);
          dispatch(setDigitalPinValue(data));
        }
        break;
      default:
        console.log('Unknown command: ' + message.command);
        break;
    }
  }, [dispatch]);

  const onWebSocketMessage = useCallback((message) => {
    processMessage(JSON.parse(message.data));
  }, [processMessage]);

  useEffect(() => {
    if (ws !== null) {
      ws.close();
    }
    if(deviceIPAddress.length < 8) {
      return;
    }
    const newWS = new WebSocket(`ws://${deviceIPAddress}:80/ws`);
    newWS.onopen = onWebSocketOpen;
    newWS.onclose = onWebSocketClose;
    newWS.onerror = onWebSocketError;
    newWS.onmessage = onWebSocketMessage;
    setWS(newWS);
    return () => {
      if (ws !== null) {
        ws.close();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceIPAddress, onWebSocketMessage]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      if(musicNote.length > 0) {
        dispatch(playNoteByName(musicNote));
        setMusicNote('');
      }
    }, 1000);
    return () => {
      clearTimeout(timerID);
    };
  }, [dispatch, musicNote]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      if (ttsEnabled && 'speechSynthesis' in window && textToSpeak.length > 0) {
        const msg = new SpeechSynthesisUtterance(textToSpeak);
        msg.voice = ttsVoices.find(voice => voice.name === ttsVoice);
        msg.pitch = ttsPitch;
        msg.rate = ttsRate;
        msg.volume = ttsVolume;
        window.speechSynthesis.speak(msg);
        setTextToSpeak('');
      }
    }, 1000);
    return () => {
      clearTimeout(timerID);
    };
  }, [textToSpeak, ttsEnabled, ttsPitch, ttsRate, ttsVoice, ttsVoices, ttsVolume]);

  useEffect(() => {
    if (nfcData.length > 0 && ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: 'NFCTagDetected',
        serialNumber: nfcSerialNumber,
        recordType: nfcRecordType,
        data: nfcData
      }));
    }
  }, [ws, nfcData, nfcRecordType, nfcSerialNumber]);

  useEffect(() => {
    if (keyPressed >= 0 && ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: 'KeypadPressed',
        key: keyPressed
      }));
    }
  }, [ws, keyPressed]);

  useEffect(() => {
    if (streamDeckKeyPressed >= 0 && ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: 'StreamDeckKeyPressed',
        key: streamDeckKeys[streamDeckKeyPressed].name,
        action: streamDeckKeys[streamDeckKeyPressed].command,
        params: streamDeckKeys[streamDeckKeyPressed].params
      }));
    }
  }, [ws, streamDeckKeys, streamDeckKeyPressed]);

  useEffect(() => {
    if (lastModifiedElecSwitch >= 0 && ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        'command': 'ElectricSwitchStateChanged',
        'switch': elecSwitches[lastModifiedElecSwitch].name,
        'state': elecSwitches[lastModifiedElecSwitch].state
      }));
    }
  }, [lastModifiedElecSwitch, elecSwitches, ws]);

  useEffect(() => {
    if(speechRecognized.length > 0 && ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: 'VoiceCommand',
        speech: speechRecognized
      }));
    }
  }, [ws, speechRecognized]);

  useEffect(() => {
    if (ws !== null && ws.readyState === WebSocket.OPEN) {
      if(rgbLedIndex >= 0) {
        ws.send(JSON.stringify({
          command: `RGBPixelColor`,
          index: rgbLedIndex,
          color: rgbLedColor
        }));
      } else {
        ws.send(JSON.stringify({
          command: `RGBPixelsColor`,
          color: rgbLedColor
        }));
      }
    }
  }, [rgbLedIndex, rgbLedColor, ws]);

  useEffect(() => {
    if (ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: `RGBLedsBrightness`,
        brightness: rgbLedBrightness
      }));
    }
  }, [rgbLedBrightness, ws]);

  useEffect(() => {
    if (ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: `Motor1Speed`,
        speed: motor1Speed
      }));
    }
  }, [ws, motor1Speed]);

  useEffect(() => {
    if (ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: `Motor2Speed`,
        speed: motor2Speed
      }));
    }
  }, [ws, motor2Speed]);

  useEffect(() => {
    if (ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: `Motor3Speed`,
        speed: motor3Speed
      }));
    }
  }, [ws, motor3Speed]);

  useEffect(() => {
    if (ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: `Motor4Speed`,
        speed: motor4Speed
      }));
    }
  }, [ws, motor4Speed]);

  useEffect(() => {
    if (ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: `Motor1Direction`,
        direction: motor1Direction
      }));
    }
  }, [ws, motor1Direction]);

  useEffect(() => {
    if (ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: `Motor2Direction`,
        direction: motor2Direction
      }));
    }
  }, [ws, motor2Direction]);

  useEffect(() => {
    if (ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: `Motor3Direction`,
        direction: motor3Direction
      }));
    }
  }, [ws, motor3Direction]);

  useEffect(() => {
    if (ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: `Motor4Direction`,
        direction: motor4Direction
      }));
    }
  }, [ws, motor4Direction]);

  useEffect(() => {
    if (terminalMessage.length > 0 && ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: `IncomingTerminalMessage`,
        message: terminalMessage
      }));
      dispatch(clearNewTerminalMessage());
    }
  }, [ws, terminalMessage, dispatch]);

  useEffect(() => {
    if (ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: `SlideSwitch1StateChanged`,
        value: slideSwitch1
      }));
    }
  }, [ws, slideSwitch1]);

  useEffect(() => {
    if (ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: `SlideSwitch2StateChanged`,
        value: slideSwitch2
      }));
    }
  }, [ws, slideSwitch2]);

  useEffect(() => {
    if (ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: `SlideSwitch3StateChanged`,
        value: slideSwitch3
      }));
    }
  }, [ws, slideSwitch3]);

  useEffect(() => {
    if (ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: `ToggleSwitch1Clicked`,
      }));
    }
  }, [ws, toggleSwitch1]);

  useEffect(() => {
    if (ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: `ToggleSwitch2Clicked`,
      }));
    }
  }, [ws, toggleSwitch2]);

  useEffect(() => {
    if (ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: `ToggleSwitch3Clicked`,
      }));
    }
  }, [ws, toggleSwitch3]);

  useEffect(() => {
    if (ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: `ToggleSwitch4Clicked`,
      }));
    }
  }, [ws, toggleSwitch4]);

  useEffect(() => {
    if (ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: `ToggleSwitch5Clicked`,
      }));
    }
  }, [ws, toggleSwitch5]);

  const onDialChange = useCallback((dial, value) => {
    if (ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: `Dial${dial}AngleChanged`,
        value: value
      }));
    }
  }, [ws]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onDialChange(1, dial1);
    }, 500);
    return () => {
      clearTimeout(timerID);
    };
  }, [dial1, onDialChange]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onDialChange(2, dial2);
    }, 500);
    return () => {
      clearTimeout(timerID);
    };
  }, [dial2, onDialChange]);

  const onServoAngleChanged = useCallback((servo, angle) => {
    if (ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: `Servo${servo}AngleChanged`,
        angle: angle
      }));
    }
  }, [ws]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onServoAngleChanged(0, servo0Angle);
    }, 500);
    return () => {
      clearTimeout(timerID);
    };
  }, [servo0Angle, onServoAngleChanged]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onServoAngleChanged(1, servo1Angle);
    }, 500);
    return () => {
      clearTimeout(timerID);
    };
  }, [servo1Angle, onServoAngleChanged]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onServoAngleChanged(2, servo2Angle);
    }, 500);
    return () => {
      clearTimeout(timerID);
    };
  }, [servo2Angle, onServoAngleChanged]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onServoAngleChanged(3, servo3Angle);
    }, 500);
    return () => {
      clearTimeout(timerID);
    };
  }, [servo3Angle, onServoAngleChanged]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onServoAngleChanged(4, servo4Angle);
    }, 500);
    return () => {
      clearTimeout(timerID);
    };
  }, [servo4Angle, onServoAngleChanged]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onServoAngleChanged(5, servo5Angle);
    }, 500);
    return () => {
      clearTimeout(timerID);
    };
  }, [servo5Angle, onServoAngleChanged]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onServoAngleChanged(6, servo6Angle);
    }, 500);
    return () => {
      clearTimeout(timerID);
    };
  }, [servo6Angle, onServoAngleChanged]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onServoAngleChanged(7, servo7Angle);
    }, 500);
    return () => {
      clearTimeout(timerID);
    };
  }, [servo7Angle, onServoAngleChanged]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onServoAngleChanged(8, servo8Angle);
    }, 500);
    return () => {
      clearTimeout(timerID);
    };
  }, [servo8Angle, onServoAngleChanged]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onServoAngleChanged(9, servo9Angle);
    }, 500);
    return () => {
      clearTimeout(timerID);
    };
  }, [servo9Angle, onServoAngleChanged]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onServoAngleChanged(10, servo10Angle);
    }, 500);
    return () => {
      clearTimeout(timerID);
    };
  }, [servo10Angle, onServoAngleChanged]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onServoAngleChanged(11, servo11Angle);
    }, 500);
    return () => {
      clearTimeout(timerID);
    };
  }, [servo11Angle, onServoAngleChanged]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onServoAngleChanged(12, servo12Angle);
    }, 500);
    return () => {
      clearTimeout(timerID);
    };
  }, [servo12Angle, onServoAngleChanged]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onServoAngleChanged(13, servo13Angle);
    }, 500);
    return () => {
      clearTimeout(timerID);
    };
  }, [servo13Angle, onServoAngleChanged]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onServoAngleChanged(14, servo14Angle);
    }, 500);
    return () => {
      clearTimeout(timerID);
    };
  }, [servo14Angle, onServoAngleChanged]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onServoAngleChanged(15, servo15Angle);
    }, 500);
    return () => {
      clearTimeout(timerID);
    };
  }, [servo15Angle, onServoAngleChanged]);

  /// PWM Pins
  const onPinPWMDutyChange = useCallback((pin, duty) => {
    if (ws !== null && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        command: `PWM${pin}Duty`,
        duty: duty
      }));
    }
  }, [ws]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onPinPWMDutyChange(1, pwm1);
    }, 1000);
    return () => {
      clearTimeout(timerID);
    };
  }, [pwm1, onPinPWMDutyChange]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onPinPWMDutyChange(2, pwm2);
    }, 1000);
    return () => {
      clearTimeout(timerID);
    };
  }, [pwm2, onPinPWMDutyChange]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onPinPWMDutyChange(3, pwm3);
    }, 1000);
    return () => {
      clearTimeout(timerID);
    };
  }, [pwm3, onPinPWMDutyChange]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onPinPWMDutyChange(4, pwm4);
    }, 1000);
    return () => {
      clearTimeout(timerID);
    };
  }, [pwm4, onPinPWMDutyChange]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onPinPWMDutyChange(5, pwm5);
    }, 1000);
    return () => {
      clearTimeout(timerID);
    };
  }, [pwm5, onPinPWMDutyChange]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onPinPWMDutyChange(6, pwm6);
    }, 1000);
    return () => {
      clearTimeout(timerID);
    };
  }, [pwm6, onPinPWMDutyChange]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onPinPWMDutyChange(7, pwm7);
    }, 1000);
    return () => {
      clearTimeout(timerID);
    };
  }, [pwm7, onPinPWMDutyChange]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onPinPWMDutyChange(8, pwm8);
    }, 1000);
    return () => {
      clearTimeout(timerID);
    };
  }, [pwm8, onPinPWMDutyChange]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onPinPWMDutyChange(9, pwm9);
    }, 1000);
    return () => {
      clearTimeout(timerID);
    };
  }, [pwm9, onPinPWMDutyChange]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      onPinPWMDutyChange(10, pwm10);
    }, 1000);
    return () => {
      clearTimeout(timerID);
    };
  }, [pwm10, onPinPWMDutyChange]);

  const handleLinearAccelerationSensor = useCallback((event) => {
    if(linearAccelerationSensor.hasReading) {
      dispatch(setLinearAccelerometerReading({x: parseFloat(linearAccelerationSensor.x.toFixed(2)), y: parseFloat(linearAccelerationSensor.y.toFixed(2)), z: parseFloat(linearAccelerationSensor.z.toFixed(2))}));
    }
  }, [dispatch]);

  useEffect(() => {
    if(isLinearAccelerationSensorEnabled) {
      if(linearAccelerationSensor === null) {
        if ('LinearAccelerationSensor' in window) {
          linearAccelerationSensor = new window.LinearAccelerationSensor();
        }
      }
      if(linearAccelerationSensor !== null) {
        linearAccelerationSensor.addEventListener('reading', handleLinearAccelerationSensor);
        linearAccelerationSensor.start();
      }
    } else {
      if(linearAccelerationSensor !== null) {
        linearAccelerationSensor.removeEventListener('reading', handleLinearAccelerationSensor);
        linearAccelerationSensor.stop();
      }
    }
  }, [ws, isLinearAccelerationSensorEnabled, handleLinearAccelerationSensor]);

  const handleGyroscopeSensor = useCallback((event) => {
    if(gyroscopeSensor.hasReading) {
      dispatch(setGyroscopeReading({x: parseFloat(gyroscopeSensor.x.toFixed(2)), y: parseFloat(gyroscopeSensor.y.toFixed(2)), z: parseFloat(gyroscopeSensor.z.toFixed(2))}));
    }
  }, [dispatch]);

  useEffect(() => {
    if(isGyroscopeSensorEnabled) {
      if(gyroscopeSensor === null) {
        if ('Gyroscope' in window) {
          gyroscopeSensor = new window.Gyroscope();
        }
      }
      if(gyroscopeSensor !== null) {
        gyroscopeSensor.addEventListener('reading', handleGyroscopeSensor);
        gyroscopeSensor.start();
      }
    } else {
      if(gyroscopeSensor !== null) {
        gyroscopeSensor.removeEventListener('reading', handleGyroscopeSensor);
        gyroscopeSensor.stop();
      }
    }
  }, [ws, isGyroscopeSensorEnabled, handleGyroscopeSensor]);

  const handleMagenetometerSensor = useCallback((event) => {
    if(magnetometerSensor.hasReading) {
      dispatch(setMagnetometerReading({x: parseFloat(magnetometerSensor.x.toFixed(2)), y: parseFloat(magnetometerSensor.y.toFixed(2)), z: parseFloat(magnetometerSensor.z.toFixed(2))}));
    }
  }, [dispatch]);

  useEffect(() => {
    if(isMagnetometerSensorEnabled) {
      if(magnetometerSensor === null) {
        if ('Magnetometer' in window) {
          magnetometerSensor = new window.Magnetometer();
        }
      }
      if(magnetometerSensor !== null) {
        magnetometerSensor.addEventListener('reading', handleMagenetometerSensor);
        magnetometerSensor.start();
      }
    } else {
      if(magnetometerSensor !== null) {
        magnetometerSensor.removeEventListener('reading', handleMagenetometerSensor);
        magnetometerSensor.stop();
      }
    }
  }, [ws, isMagnetometerSensorEnabled, handleMagenetometerSensor]);

  const handleAmbientLightSensor = useCallback((event) => {
    if(ambientLightSensor.hasReading) {
      dispatch(setAmbientLightReading(ambientLightSensor.illuminance));
    }
  }, [dispatch]);

  useEffect(() => {
    if(isAmbientLightEnabled) {
      if(ambientLightSensor === null) {
        if ('AmbientLightSensor' in window) {
          ambientLightSensor = new window.AmbientLightSensor();
        }
      }
      if(ambientLightSensor !== null) {
        ambientLightSensor.addEventListener('reading', handleAmbientLightSensor);
        ambientLightSensor.start();
      }
    } else {
      if(ambientLightSensor !== null) {
        ambientLightSensor.removeEventListener('reading', handleAmbientLightSensor);
        ambientLightSensor.stop();
      }
    }
  }, [ws, isAmbientLightEnabled, handleAmbientLightSensor]);


  const handleGPSPosition = useCallback((position) => {
    dispatch(setGPSReading({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      altitude: position.coords.altitude || 0,
      accuracy: position.coords.accuracy || 0,
      altitudeAccuracy: position.coords.altitudeAccuracy || 0,
      heading: position.coords.heading || 0,
      speed: position.coords.speed || 0,
    }));
  }, [dispatch]);

  useEffect(() => {
    if(isGPSEnabled) {
      if(gpsWatchID !== null) {
        navigator.geolocation.clearWatch(gpsWatchID);
        gpsWatchID = null;
      }
      if(gpsWatchID === null) {
        if ('geolocation' in navigator) {
          gpsWatchID = navigator.geolocation.watchPosition((position) => {
            handleGPSPosition(position);
          });
        }
      }
    } else {
      if(gpsWatchID !== null) {
        navigator.geolocation.clearWatch(gpsWatchID);
        gpsWatchID = null;
      }
    }
  }, [ws, isGPSEnabled, handleGPSPosition]);

  return (
    <Outlet />
  )
};

App.propTypes = {}

export default App;
