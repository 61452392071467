import React from 'react';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ApplicationBar from '../../components/ApplicationBar';

function Register(props) {
  return (
    <div className="App">
      <header className="App-header">
        <ApplicationBar />
      </header>
      <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', overflowY: 'scroll', width: '-webkit-fill-available', minHeight: '-webkit-fill-available', backgroundColor: "#FCF4ED"}}>
        <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width: '-webkit-fill-available', ml:2, mt:5, mb:2, mr:2}}>
          <img src="/stembuddy.png" alt="STEM Buddies Logo" width="100%" />
        </Box>
        <Card sx={{ maxWidth: '90vw', ml:2, mr:2, width: '-webkit-fill-available'}}>
          <CardHeader title="Register"/>
          <CardContent>
            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', width: '-webkit-fill-available'}}>
              <TextField
                id="firstName"
                label="First Name"
                placeholder="John"
                sx={{width: '-webkit-fill-available', mb:2, ml: 1, mr: 1}}
              />
              <TextField
                id="lastName"
                label="Last Name"
                placeholder="Doe"
                sx={{width: '-webkit-fill-available', mb:2, ml: 1, mr: 1}}
              />
            </Box>
            <TextField
              id="username"
              label="Username"
              placeholder="username"
              sx={{width: '-webkit-fill-available', mb:2, ml: 1, mr: 1}}
            />
            <TextField
              id="email"
              label="Email"
              placeholder="name@domain.com"
              type="email"
              sx={{width: '-webkit-fill-available', mb:2, ml: 1, mr: 1}}
            />
            <TextField
              id="password"
              label="Password"
              placeholder="Secret123"
              sx={{width: '-webkit-fill-available', mb:2, ml: 1, mr: 1}}
            />
            <TextField
              id="confirm-password"
              label="Confirm Password"
              placeholder="Secret123"
              sx={{width: '-webkit-fill-available', mb:0, ml: 1, mr: 1}}
            />
          </CardContent>
          <CardActions sx={{justifyContent: 'end', mr:1}}>
            <Button size="small">Sign Up</Button>
          </CardActions>
        </Card>
        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', mt:1}}>
          <Typography variant="body2" color="text.secondary" sx={{ml: 1, mr: 0}}>
            Already have an account?
          </Typography>
          <Link href="/login">
            <Typography variant="body2" color="text.secondary" sx={{ml: 1, mr: 1}}>
              Login
            </Typography>
          </Link>
        </Box>
        <Box sx={{minHeight: '10rem'}}></Box>
      </Box>
    </div>
  )
}

Register.propTypes = {}

export default Register
