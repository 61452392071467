import React, {useEffect, useState, useRef, useCallback} from 'react';
import PropTypes from 'prop-types';
import ApplicationBar from '../../components/ApplicationBar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Slider from '@mui/material/Slider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import ColorizeIcon from '@mui/icons-material/Colorize';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Typography } from '@mui/material';
import { FastAverageColor } from 'fast-average-color';

const fac = new FastAverageColor();

const marks = [
  {
    value: 0,
    label: '3 Bit',
  },
  {
    value: 50,
    label: '15 Bit',
  },
  {
    value: 100,
    label: '24 Bit',
  }
];

function valueText(value) {
  return marks.find((mark) => mark.value === value).label;
}

function valueLabelFormat(value) {
  return marks.find((mark) => mark.value === value).label;
}

function ColorDetection(props) {
  const [colorCalcMethod, setColorCalcMethod] = useState('dominant');
  const [colorType, setColorType] = useState('color');
  const [colorGridSize, setColorGridSize] = useState('1x1');
  const [showCameraImage, setShowCameraImage] = useState(false);
  const [enableColorDetection, setEnableColorDetection] = useState(false);
  const videoRef = useRef(null);
  const colorResultRef = useRef(null);

  const handleColorCalcMethodChange = (event) => {
    setColorCalcMethod(event.target.value);
  };

  const handleColorTypeChange = (event) => {
    setColorType(event.target.value);
  };

  const handleColorGridSizeChange = (event) => {
    setColorGridSize(event.target.value);
  };

  const [checked, setChecked] = React.useState(['color-detection', 'flash']);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleShowCameraImageChange = (event) => {
    setShowCameraImage(event.target.checked);
  }

  const handleEnableColorDetectionChange = (event) => {
    setEnableColorDetection(event.target.checked);
  };

  const detectColor = useCallback(() => {
    fac.getColorAsync(document.querySelector('#videoColorDetection'), { algorithm: colorCalcMethod })
    .then(color => {
      colorResultRef.current.style.backgroundColor = color.rgba.toString();
    })
    .catch(e => {
      console.log(e);
    });
  }, [colorCalcMethod]);

  useEffect(() => {
    const id = setInterval(() => {
      if (enableColorDetection) {
        detectColor();
      }
    }, 500);
    return () => clearInterval(id);
  }, [enableColorDetection, detectColor]);

  useEffect(() => {
    navigator.mediaDevices && navigator.mediaDevices.getUserMedia({ video: true, audio: false }).then((stream) => {
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <ApplicationBar back="/camera" title={'Color Detection'} />
      </header>
      <Box sx={{mt:2, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', overflowY: 'scroll'}}>
        <Card sx={{}} elevation={5}>
          <video ref={videoRef} id="videoColorDetection" width="320" height="240" autoPlay style={{display: showCameraImage ? 'block' : 'none'}}></video>
          <div ref={colorResultRef} id="canvasColorDetection" style={{display: showCameraImage ? 'none': 'block', width: '240px', height: '240px'}}></div>
        </Card>
        <Box sx={{mt:1, width: '100%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <ListItem>
              <ListItemIcon>
                <ColorizeIcon />
              </ListItemIcon>
              <ListItemText id="switch-list-label-color-detection" primary="Enable color detection" />
              <Switch
                edge="end"
                onChange={handleEnableColorDetectionChange}
                checked={enableColorDetection}
                inputProps={{
                  'aria-labelledby': 'switch-list-label-color-detection',
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FlashOnIcon />
              </ListItemIcon>
              <ListItemText id="switch-list-label-flash" primary="Enable flash light" />
              <Switch
                edge="end"
                onChange={handleToggle('flash')}
                checked={checked.indexOf('flash') !== -1}
                inputProps={{
                  'aria-labelledby': 'switch-list-label-flash',
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CameraAltIcon />
              </ListItemIcon>
              <ListItemText id="switch-list-label-image" primary="Show camera image" />
              <Switch
                edge="end"
                onChange={handleShowCameraImageChange}
                checked={showCameraImage}
                inputProps={{
                  'aria-labelledby': 'switch-list-label-image',
                }}
              />
            </ListItem>
          </List>
        </Box>
        <Box sx={{width: '100%', mt:1}}>
          <FormControl sx={{flexDirection: 'row', alignItems: 'center', justifyContent: "flex-start", width: '-webkit-fill-available', mt: 0}}>
            <FormLabel id="color-grid-size-buttons-group-label" sx={{textAlign: 'left', mr: 2, ml: 2, width: '3rem', fontWeight: 700}}>Grid:</FormLabel>
            <RadioGroup
              row
              aria-labelledby="color-grid-size-buttons-group-label"
              name="color-grid-size-buttons-group"
              value={colorGridSize}
              onChange={handleColorGridSizeChange}
            >
              <FormControlLabel value="1x1" control={<Radio />} label="1x1" />
              <FormControlLabel value="3x3" control={<Radio />} label="3x3" />
              <FormControlLabel value="5x5" control={<Radio />} label="5x5" />
            </RadioGroup>
          </FormControl>
          <FormControl sx={{flexDirection: 'row', alignItems: 'center', justifyContent: "flex-start", width: '-webkit-fill-available', mt: 1}}>
            <FormLabel id="color-calc-method-buttons-group-label" sx={{textAlign: 'left', mr: 2, ml: 2, width: '3rem', fontWeight: 700}}>Type:</FormLabel>
            <RadioGroup
              row
              aria-labelledby="color-calc-method-buttons-group-label"
              name="color-calc-method-buttons-group"
              value={colorCalcMethod}
              onChange={handleColorCalcMethodChange}
            >
              <FormControlLabel value="simple" control={<Radio />} label="Simple" />
              <FormControlLabel value="sqrt" control={<Radio />} label="Sqrt" />
              <FormControlLabel value="dominant" control={<Radio />} label="Dominant" />
            </RadioGroup>
          </FormControl>
          <FormControl sx={{flexDirection: 'row', alignItems: 'center', justifyContent: "flex-start", width: '-webkit-fill-available', mt: 1}}>
            <FormLabel id="color-type-buttons-group-label" sx={{textAlign: 'left', mr: 2, ml: 2, width: '3rem', fontWeight: 700}}>Color:</FormLabel>
            <RadioGroup
              row
              aria-labelledby="color-type-buttons-group-label"
              name="color-type-buttons-group"
              value={colorType}
              onChange={handleColorTypeChange}
            >
              <FormControlLabel value="color" control={<Radio />} label="Colored" />
              <FormControlLabel value="grayscale" control={<Radio />} label="Grayscale" />
            </RadioGroup>
          </FormControl>
          <Box sx={{mt:1, ml: 4, mr: 4}}>
            <Slider
              aria-label="Color Depth"
              defaultValue={100}
              valueLabelFormat={valueLabelFormat}
              getAriaValueText={valueText}
              step={50}
              valueLabelDisplay="auto"
              marks={marks}
            />
          </Box>
        </Box>
      </Box>
    </div>
  )
}

ColorDetection.propTypes = {}

export default ColorDetection
