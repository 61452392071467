import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import ApplicationBar from '../../components/ApplicationBar';

function Gamepad(props) {
  const [gamepad, setGamepad] = useState([
    {
      name: "Digital",
      desc: "Push Button based Gamepad",
    },
    {
      name: "Analog",
      desc: "Analog Joystick based Gamepad",
    },
    {
      name: "Gyroscope",
      desc: "Phone Gyroscope based Gamepad",
    },
  ]);

  return (
    <div className="App">
      <header className="App-header">
        <ApplicationBar back="/" title={'Gamepad'} />
      </header>
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {
              gamepad.map((type, index) => (
                <React.Fragment key={`gamepad-${type.name}`}>
                  <ListItem disablePadding alignItems="flex-start">
                    <ListItemButton>
                      <ListItemIcon>
                        <SportsEsportsIcon sx={{ fontSize: 40 }} color="action"/>
                      </ListItemIcon>
                      <ListItemText
                        primary={type.name}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: 'inline', mr:1 }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {type.desc}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </React.Fragment>
              ))
            }
          </List>
        </Box>
    </div>
  )
}

Gamepad.propTypes = {}

export default Gamepad
