import { createSlice } from '@reduxjs/toolkit'

export const keypadSlice = createSlice({
  name: 'keypad',
  initialState: {
    keyPressed: -1,
  },
  reducers: {
    setKeyPressed: (state, action) => {
      state.keyPressed = action.payload;
    }
  }
})

// Action creators are generated for each case reducer function
export const { setKeyPressed  } = keypadSlice.actions

export default keypadSlice.reducer