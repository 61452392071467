import { createSlice } from '@reduxjs/toolkit'

export const gpioMonitorSlice = createSlice({
  name: 'gpioMonitor',
  initialState: {
    analog: [],
    digital: [],
  },
  reducers: {
    setDigitalPinValue: (state, action) => {
      const { pin, value } = action.payload;
      const index = state.digital.findIndex(item => item.pin === pin);
      if (index !== -1) {
        state.digital[index].value = value;
        state.digital = [...state.digital];
      } else {
        state.digital = [...state.digital, { pin, value }];
      }
    },
    setAnalogPinValue: (state, action) => {
      const { pin, value } = action.payload;
      const index = state.analog.findIndex(item => item.pin === pin);
      if (index !== -1) {
        state.analog[index].value = value;
        state.analog = [...state.analog];
      } else {
        state.analog = [...state.analog, { pin, value }];
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const { setDigitalPinValue, setAnalogPinValue } = gpioMonitorSlice.actions

export default gpioMonitorSlice.reducer