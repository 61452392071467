import { createSlice } from '@reduxjs/toolkit'

export const speechRecognitionSlice = createSlice({
  name: 'speechRecognition',
  initialState: {
    speechRecognized: '',
  },
  reducers: {
    setRecognizedSpeech: (state, action) => {
      state.speechRecognized = action.payload;
    }
  }
})

// Action creators are generated for each case reducer function
export const { setRecognizedSpeech  } = speechRecognitionSlice.actions

export default speechRecognitionSlice.reducer