import { createSlice } from '@reduxjs/toolkit'

export const ttsSlice = createSlice({
  name: 'tts',
  initialState: {
    enabled: true,
    voiceName: 'Google US English',
    pitch: 1.0,
    rate: 1.0,
    volume: 50,
  },
  reducers: {
    setTTSVoiceParams: (state, action) => {
      state.enabled = action.payload.enabled;
      state.voiceName = action.payload.voiceName;
      state.pitch = action.payload.pitch;
      state.rate = action.payload.rate;
      state.volume = action.payload.volume;
    }
  }
})

// Action creators are generated for each case reducer function
export const { setTTSVoiceParams  } = ttsSlice.actions

export default ttsSlice.reducer