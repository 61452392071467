import { createSlice } from '@reduxjs/toolkit'

export const terminalSlice = createSlice({
  name: 'terminal',
  initialState: {
    messages: [
    ],
    newMessage: ''
  },
  reducers: {
    addIncomingMessage: (state, action) => {
      const d = new Date();
      state.messages = [...state.messages, {
        msg: action.payload,
        timeStamp: { year: d.getFullYear(), month: d.getMonth(), day: d.getDate(), hours: d.getHours(), minutes: d.getMinutes(), second: d.getSeconds(), milliseconds: d.getMilliseconds()},
        direction: "incoming"
      }];
    },
    sendOutgoingMessage: (state, action) => {
      const d = new Date();
      state.messages.push({
        msg: action.payload,
        timeStamp: { year: d.getFullYear(), month: d.getMonth(), day: d.getDate(), hours: d.getHours(), minutes: d.getMinutes(), second: d.getSeconds(), milliseconds: d.getMilliseconds()},
        direction: "outgoing"
      });
      state.newMessage = action.payload;
    },
    clearNewTerminalMessage: (state) => {
      state.newMessage = '';
    }
  }
})

// Action creators are generated for each case reducer function
export const { addIncomingMessage, sendOutgoingMessage, clearNewTerminalMessage } = terminalSlice.actions

export default terminalSlice.reducer