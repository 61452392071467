import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ApplicationBar from '../../components/ApplicationBar';
import { useNavigate } from "react-router-dom";

function ServoList(props) {
  const navigate = useNavigate();

  const handleServoClick = (index) => {
    navigate(`/servo/${index}`);
  };

  return (
    <div className="App">
      <header className="App-header">
        <ApplicationBar back="/" title={'Servo'} />
      </header>
      <Box sx={{ bgcolor: 'background.paper', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 2 }}>
        <Grid container spacing={{ xs: 4, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }} >
          {Array.from(Array(16)).map((_, index) => (
            <Grid item xs={1} sm={2} md={2} key={index}>
              <Button variant="contained" sx={{minWidth: '100%', fontWeight: 700, fontSize: '2rem', borderRadius: '50%'}} onClick={(e) => handleServoClick(index)}>{index+1}</Button>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  )
}

ServoList.propTypes = {}

export default ServoList
