import { createSlice } from '@reduxjs/toolkit'

export const lcd1602Slice = createSlice({
  name: 'lcd1602',
  initialState: {
    lines: [
      [
        {char: '', x: 105, y: 128},
        {char: '', x: 145, y: 128},
        {char: '', x: 186, y: 128},
        {char: '', x: 226, y: 128},
        {char: '', x: 266, y: 128},
        {char: '', x: 306, y: 128},
        {char: '', x: 346, y: 128},
        {char: '', x: 386, y: 128},
        {char: '', x: 426, y: 128},
        {char: '', x: 467, y: 128},
        {char: '', x: 507, y: 128},
        {char: '', x: 547, y: 128},
        {char: '', x: 587, y: 128},
        {char: '', x: 627, y: 128},
        {char: '', x: 667, y: 128},
        {char: '', x: 707, y: 128},
      ],
      [
        {char: '', x: 105, y: 192},
        {char: '', x: 145, y: 192},
        {char: '', x: 186, y: 192},
        {char: '', x: 226, y: 192},
        {char: '', x: 266, y: 192},
        {char: '', x: 306, y: 192},
        {char: '', x: 346, y: 192},
        {char: '', x: 386, y: 192},
        {char: '', x: 426, y: 192},
        {char: '', x: 467, y: 192},
        {char: '', x: 507, y: 192},
        {char: '', x: 547, y: 192},
        {char: '', x: 587, y: 192},
        {char: '', x: 627, y: 192},
        {char: '', x: 667, y: 192},
        {char: '', x: 707, y: 192},
      ]
    ],
    currentLine: 0,
    currentColumn: 0,
  },
  reducers: {
    clear: (state) => {
      state.lines = [
        [
          {char: '', x: 105, y: 128},
          {char: '', x: 145, y: 128},
          {char: '', x: 186, y: 128},
          {char: '', x: 226, y: 128},
          {char: '', x: 266, y: 128},
          {char: '', x: 306, y: 128},
          {char: '', x: 346, y: 128},
          {char: '', x: 386, y: 128},
          {char: '', x: 426, y: 128},
          {char: '', x: 467, y: 128},
          {char: '', x: 507, y: 128},
          {char: '', x: 547, y: 128},
          {char: '', x: 587, y: 128},
          {char: '', x: 627, y: 128},
          {char: '', x: 667, y: 128},
          {char: '', x: 707, y: 128},
        ],
        [
          {char: '', x: 105, y: 192},
          {char: '', x: 145, y: 192},
          {char: '', x: 186, y: 192},
          {char: '', x: 226, y: 192},
          {char: '', x: 266, y: 192},
          {char: '', x: 306, y: 192},
          {char: '', x: 346, y: 192},
          {char: '', x: 386, y: 192},
          {char: '', x: 426, y: 192},
          {char: '', x: 467, y: 192},
          {char: '', x: 507, y: 192},
          {char: '', x: 547, y: 192},
          {char: '', x: 587, y: 192},
          {char: '', x: 627, y: 192},
          {char: '', x: 667, y: 192},
          {char: '', x: 707, y: 192},
        ]
      ];
      state.currentLine = 0;
      state.currentColumn = 0;
    },
    setCursor: (state, action) => {
      console.log(action.payload);
      state.currentLine = action.payload.line;
      state.currentColumn = action.payload.column;
    },
    print : (state, action) => {
      const msg = action.payload;
      for (let i = 0; i < msg.length && state.currentColumn < 16; i++) {
        state.lines[state.currentLine][state.currentColumn].char = msg[i];
        state.currentColumn++;
      }
    },
  }
})

// Action creators are generated for each case reducer function
export const { clear, setCursor, print } = lcd1602Slice.actions

export default lcd1602Slice.reducer