import { createSlice } from '@reduxjs/toolkit'

export const deviceSlice = createSlice({
  name: 'device',
  initialState: {
    ipAddress: localStorage.getItem('device_ip_address') || '',
  },
  reducers: {
    setDeviceIPAddress: (state, action) => {
      state.ipAddress = action.payload;
      localStorage.setItem('device_ip_address', action.payload);
    }
  }
})

// Action creators are generated for each case reducer function
export const { setDeviceIPAddress  } = deviceSlice.actions

export default deviceSlice.reducer