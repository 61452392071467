import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ApplicationBar from '../../components/ApplicationBar';
import JogDial from '../../components/JogDial';
import "./switch.css";
import { useSelector, useDispatch } from 'react-redux';
import { setSwitch1, setSwitch2, setSwitch3, button1Toggled, button2Toggled, button3Toggled, button4Toggled, button5Toggled, setDial1, setDial2 } from '../../reducers/inputs/inputsSlice';

function Inputs(props) {
  const dispatch = useDispatch();
  const switch1 = useSelector(state => state.inputs.switch1);
  const switch2 = useSelector(state => state.inputs.switch2);
  const switch3 = useSelector(state => state.inputs.switch3);
  const dial1 = useSelector(state => state.inputs.dial1);
  const dial2 = useSelector(state => state.inputs.dial2);

  const [orientationAngle, setOrientationAngle] = useState(0);

  useEffect(() => {
    window.addEventListener("orientationchange", (event) => {
      setOrientationAngle(event.target.screen.orientation.angle);
    });
  }, []);

  return (
    <div className="App">
      <Box sx={{ flexDirection: (orientationAngle === 0) ? 'column': 'row', color: '#FFF', bgcolor: 'background.paper', width: '-webkit-fill-available', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', margin: 2, mb: 0, mt:0, overflowY: 'scroll'}}>
        <Box sx={{display:'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: "relative"}}>
          <JogDial id="knob1" key={`knob1${orientationAngle}`} onChange={degree => dispatch(setDial1(degree))}/>
          <span style={{position: "absolute"}}>{dial1}°</span>
        </Box>
        <Box sx={{display:'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <Box className="switchT toggleonoff">
            <input type="checkbox" checked={switch1} onChange={e => dispatch(setSwitch1(e.target.checked))}/>
            <label><i></i></label>
          </Box>
          <Box className="switchT toggleonoff" sx={{mt:3, mb:3}}>
            <input type="checkbox" checked={switch2} onChange={e => dispatch(setSwitch2(e.target.checked))}/>
            <label><i></i></label>
          </Box>
          <Box className="switchT toggleonoff">
            <input type="checkbox" checked={switch3} onChange={e => dispatch(setSwitch3(e.target.checked))}/>
            <label><i></i></label>
          </Box>
        </Box>
        <Box sx={{display:'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: "relative"}}>
          <JogDial id="knob2" key={`knob2${orientationAngle}`} onChange={degree => dispatch(setDial2(degree))}/>
          <span style={{position: "absolute"}}>{dial2}°</span>
        </Box>
      </Box>
      <Box sx={{mt: 3, color: '#FFF', bgcolor: 'background.paper', width: '-webkit-fill-available', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', margin: 2, mt: 0, flexDirection: 'row'}}>
        <button className="buttonDark buttonRound" onClick={e => dispatch(button1Toggled())}>
          <i className="icon"></i>
        </button>
        <button className="buttonDark buttonRound" onClick={e => dispatch(button2Toggled())}>
          <i className="icon"></i>
        </button>
        <button className="buttonDark buttonRound" onClick={e => dispatch(button3Toggled())}>
          <i className="icon"></i>
        </button>
        <button className="buttonDark buttonRound" onClick={e => dispatch(button4Toggled())}>
          <i className="icon"></i>
        </button>
        <button className="buttonDark buttonRound" onClick={e => dispatch(button5Toggled())}>
          <i className="icon"></i>
        </button>
      </Box>
    </div>
  )
}

Inputs.propTypes = {}

export default Inputs
