import React, { useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import ImageList from '@mui/material/ImageList';
import IconButton from '@mui/material/IconButton';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined';
import ApplicationBar from '../../components/ApplicationBar';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import { useSelector, useDispatch } from 'react-redux';
import { setKeyName, setKeyImage, setKeyCommand, setKeyParams, setKeyPressed } from '../../reducers/streamDeck/streamDeckSlice';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, selected, theme) {
  return {
    fontWeight:
      (selected === name)
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
  };
}

const commands = [
  {
    name: 'url',
    label: "Open URL",
  },
  {
    name: 'application',
    label: "Launch Application"
  },
  {
    name: 'keyboard',
    label: "Send Keys"
  }
]

export default function StreamDeck(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));
  const [tab, setTab] = useState(0);
  const deckKeys = useSelector((state) => state.streamDeck.keys);

  const onKeyPressed = (index) => {
    dispatch(setKeyPressed({ index: index }));
    setTimeout(() => {
      dispatch(setKeyPressed({ index: -1 }));
    }, 1000);
  };

  return (
    <Box className="App" sx={{display:"flex", flexDirection: "column", minHeight:"100vh"}}>
      {(tab === 1) && (
        <header className="App-header">
          <ApplicationBar back="/emulators" title={'Stream Deck'} />
        </header>
      )}
      <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', }}>
        {(tab === 0) && (
          <ImageList sx={{ my:1, mx:1, mt:0, backgroundColor: "#FFF", p:2, py: 1, pb: 5, mb: 5 }} cols={matchDownMd ? 2 : 4} gap={8}>
            {deckKeys.map((dKey, index) => (
              <Paper elevation={3} key={`Stream-Deck-Keys-${index}`}>
                <IconButton aria-label="delete" size="large" sx={{}} onClick={e => onKeyPressed(index)}>
                  <ImageListItem sx={{borderWidth: 0, borderStyle: "solid", borderColor: "#CCC", padding:1}}>
                    <img
                      src={`${dKey.img}?w=248&fit=crop&auto=format`}
                      srcSet={`${dKey.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      alt={dKey.title}
                      loading="lazy"/>
                  </ImageListItem>
                </IconButton>
              </Paper>
            ))}
          </ImageList>
        )}
        {(tab === 1) && (
          <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', mb: 5, pb: 3 }}>
            {
              deckKeys.map((dKey, index) => (
                <Accordion sx={{width: '-webkit-fill-available'}} key={`StreamDeckKey${index}`}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`deck-key${index}-content`}
                    id={`deck-key${index}-header`}
                  >
                    <Typography>{`Key ${index+1}`}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                  <TextField
                      id={`deck-key${index}-name`}
                      label={"Name"}
                      value={dKey.name}
                      onChange={e => dispatch(setKeyName({index: index, name: e.target.value}))}
                      sx={{mt: 0, mx: 1, width: '-webkit-fill-available', mb:1 }}
                    />
                    <TextField
                      id={`deck-key${index}-image`}
                      label="Image"
                      value={dKey.img}
                      onChange={e => dispatch(setKeyImage({index: index, image: e.target.value}))}
                      sx={{mt: 1, mx: 1, width: '-webkit-fill-available', mb:1 }}
                    />
                    <FormControl sx={{ m: 1, pb: 1, display: 'flex', justifyContent: 'stretch', flexDirection: 'column', textAlign: 'left'}}>
                      <InputLabel id={`"select-key${index}-action-label"`}>Action</InputLabel>
                      <Select
                        labelId={`select-key${index}-action-label`}
                        id={`select-key${index}-action`}
                        value={dKey.command}
                        onChange={e => dispatch(setKeyCommand({index: index, command: e.target.value}))}
                        input={<OutlinedInput label="Action" />}
                        MenuProps={MenuProps}
                      >
                        {
                          commands.map((command) => (
                            <MenuItem
                              key={command.name}
                              value={command.name}
                              style={getStyles(command.name, dKey.command, theme)}
                            >
                              {command.label}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                    <TextField
                      id="key1-params"
                      label="Parameters"
                      value={dKey.params}
                      onChange={e => dispatch(setKeyParams({index: index, params: e.target.value}))}
                      sx={{mt: 0, mx: 1, width: '-webkit-fill-available'}}
                    />
                  </AccordionDetails>
                </Accordion>
              ))
            }
          </Box>
        )}
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%' }} elevation={3}>
          <BottomNavigation
            showLabels
            value={tab}
            onChange={(event, newValue) => {
              setTab(newValue);
            }}
          >
            <BottomNavigationAction label="Keyboard" icon={<KeyboardAltOutlinedIcon fontSize="large"/>} />
            <BottomNavigationAction label="Settings" icon={<SettingsOutlinedIcon fontSize="large"/>} />
          </BottomNavigation>
        </Paper>
      </Box>
    </Box>
  )
};
