import React, {useState} from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ApplicationBar from '../../components/ApplicationBar';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { useSelector, useDispatch } from 'react-redux';
import { clearNotifications } from '../../reducers/notification/notificationSlice';
import {db, messaging, getMessagingToken, onMessage} from '../../firebase/config';
import moment from 'moment';

const fabStyle = {
  position: 'absolute',
  bottom: 72,
  right: 16,
};

function Notifications(props) {
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const notifications = useSelector(state => state.notification.notifications);
  const [userToken, setUserToken] = useState(window.sessionStorage.getItem('pushNotificationsToken') || '');

  const [notificationEnabled, setNotificationEnabled] = useState(false);
  const [showUserToken, setShowUserToken] = useState(false);

  const handleToggleEnableNotification = (value) => {
    setNotificationEnabled(value);
    if(value) {
      onPushNotificationsRequestPermission();
    }
  };

  const handleCopyTokenToClipboard = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(userToken);
  };

  const handleToggleShowUserToken = (value) => {
    setShowUserToken(value);
  };

  const clearAllNotifications = (e) => {
    e.preventDefault();
    dispatch(clearNotifications());
  };

  const onPushNotificationsRequestPermission = () => {
    window.Notification.requestPermission(permission => {
      if (permission === 'granted') {
        getMessagingToken().then(token => {
          setUserToken(token);
          window.sessionStorage.setItem('pushNotificationsToken', token);
        });
      } else {
        window.console.warn('Notifications not granted');
      }
    });
  };

  return (
    <div className="App">
      <header className="App-header">
        <ApplicationBar back="/iot" title={'Notifications'} />
      </header>
      {
        (tab === 1) && (
          <>
            <List
              sx={{ width: '100%', bgcolor: 'background.paper', pb:0 }}
            >
              <ListItem>
                <ListItemIcon>
                  <NotificationsIcon />
                </ListItemIcon>
                <ListItemText id="switch-list-label-notifications" primary="Enable Notifications" />
                <Switch
                  edge="end"
                  onChange={e => handleToggleEnableNotification(e.target.checked)}
                  checked={notificationEnabled}
                  inputProps={{
                    'aria-labelledby': 'switch-list-label-notifications',
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <VisibilityIcon />
                </ListItemIcon>
                <ListItemText id="switch-list-label-notifications" primary="Show User Token" />
                <Switch
                  edge="end"
                  onChange={e => handleToggleShowUserToken(e.target.checked)}
                  checked={showUserToken}
                  inputProps={{
                    'aria-labelledby': 'switch-list-label-notifications',
                  }}
                />
              </ListItem>
            </List>
            {
              showUserToken && (
                <FormControl sx={{width: '-webkit-fill-available', ml: 2, mr: 2, mb:2, mt:2}} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-usertoken">User Token</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-usertoken"
                    type={'text'}
                    value={userToken}
                    readOnly
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="copy user token to clipboard"
                          onClick={handleCopyTokenToClipboard}
                          edge="end"
                          disabled={userToken.length === 0}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="User Token"
                    placeholder=""
                    sx={{width: '-webkit-fill-available', color: "#000"}}
                  />
                </FormControl>
              )
            }
          </>
        )
      }
      {
        (tab === 0) && (
          <>
            {
              notifications.length === 0 && (
                <Box sx={{ width: '100%', bgcolor: 'background.paper', overflowY: 'scroll', pt:0, mt:5 }}>
                  No new notifications
                </Box>
              )
            }
            <List sx={{ width: '100%', bgcolor: 'background.paper', overflowY: 'scroll', pt:0 }}>
              {
                notifications.map((notification, index) => (
                  <React.Fragment key={`notification${index}`}>
                    <ListItem alignItems="center">
                      <ListItemAvatar sx={{mt:0, mr:2}}>
                        <Avatar alt={notification.title} src={notification.img} variant="square" sx={{width: 64, height: 64}}/>
                      </ListItemAvatar>
                      <ListItemText
                        primary={notification.title}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {moment(notification.timeStamp).fromNow()}
                            </Typography>
                            {" — "} {notification.desc}
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </React.Fragment>
                ))
              }
            </List>
            <Box sx={{ '& > :not(style)': { m: 1 } }}>
              <Fab color="primary" aria-label="delete-all" sx={fabStyle} onClick={clearAllNotifications}>
                <DeleteIcon sx={{width: '2rem', height: '2rem'}}/>
              </Fab>
            </Box>
          </>
        )
      }
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%' }} elevation={3}>
          <BottomNavigation
            showLabels
            value={tab}
            onChange={(event, newValue) => {
              setTab(newValue);
            }}
          >
            <BottomNavigationAction label="Recent" icon={<NotificationsIcon fontSize="large"/>} />
            <BottomNavigationAction label="Settings" icon={<SettingsIcon fontSize="large"/>} />
          </BottomNavigation>
        </Paper>
    </div>
  )
}

Notifications.propTypes = {}

export default Notifications
