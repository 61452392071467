import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';
import { setDeviceIPAddress } from '../../reducers/device/deviceSlice';

function IPAddressDialog({open, handleClose}) {
  const dispatch = useDispatch();
  const deviceIPAddress = useSelector(state => state.device.ipAddress);
  const [ipAddress, setIPAddress] = useState(deviceIPAddress);

  const handleIPAddressSubmit = (event) => {
    event.preventDefault();
    dispatch(setDeviceIPAddress(ipAddress));
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Connect</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To connect to the device, please enter the device IP address.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="ipAddress"
          label="IP Address"
          type="text"
          fullWidth
          variant="standard"
          placeholder='192.168.1.10'
          value={ipAddress}
          onChange={e => setIPAddress(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleIPAddressSubmit}>Connect</Button>
      </DialogActions>
    </Dialog>
  )
}

IPAddressDialog.propTypes = {}

export default IPAddressDialog
