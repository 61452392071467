import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import ApplicationBar from '../../components/ApplicationBar';
import { Knob } from 'primereact/knob';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { setServoAngle } from '../../reducers/servo/servoSlice';

function ServoControl(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const servoAngle = useSelector(state => state.servo[`servo${id}`]);

  const getServoName = (index) => {
    index = parseInt(index, 10) || 0;
    index = index + 1;
    return `Servo ${index}`;
  };

  const handleServoAngleChange = (e) => {
    dispatch(setServoAngle({servo: id, angle: e.value}));
  };

  return (
    <div className="App">
      <header className="App-header">
        <ApplicationBar back="/servo" title={'Servo Control'} />
      </header>
      <Box sx={{ color: '#FFF', bgcolor: 'background.paper', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 2, flexDirection: 'column' }}>
        <Knob value={servoAngle} min={0} max={180} size={400} valueColor={"MediumTurquoise"} rangeColor={"SlateGray"} textColor={"#000"} onChange={handleServoAngleChange} />
        <Typography variant="h1" component="div" sx={{ flexGrow: 1, textAlign: 'center', fontSize: '3rem', fontWeight: 700, color: '#000' }}>
          {
            getServoName(id)
          }
        </Typography>
      </Box>
    </div>
  )
}

ServoControl.propTypes = {}

export default ServoControl
