import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import AceEditor from "react-ace";
import ApplicationBar from '../../components/ApplicationBar';

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";

function CodeEditor(props) {
  const [code, setCode] = useState('');

  const onCodeChange = (newCode) => {
    setCode(newCode);
  };

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div className="App NoScroll">
      <header className="App-header">
        <ApplicationBar back="/" title={'Code Editor'} />
      </header>
      <AceEditor
        mode="python"
        theme="monokai"
        onChange={onCodeChange}
        name="PY_EDITOR"
        fontSize={28}
        editorProps={{ $blockScrolling: true }}
      />
    </div>
  )
}

CodeEditor.propTypes = {}

export default CodeEditor
