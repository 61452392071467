import React, {useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import ApplicationBar from '../../components/ApplicationBar';
import { Typography } from '@mui/material';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { useSelector, useDispatch } from 'react-redux';
import { setTTSVoiceParams } from '../../reducers/tts/ttsSlice';

const voiceSpeeds = [
  0.25,
  0.5,
  0.75,
  1.0,
  1.25,
  1.5,
  1.75,
  2.0,
];

const voicePitches = [
  0.25,
  0.5,
  0.75,
  1.0,
  1.25,
  1.5,
  1.75,
  2.0,
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, selected, theme) {
  return {
    fontWeight:
      (selected === name)
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
  };
}

function TTS(props) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [ttsVoices, setTTSVoices] = useState([]);
  const name = useSelector(state => state.tts.voiceName);
  const pitch = useSelector(state => state.tts.pitch);
  const rate = useSelector(state => state.tts.rate);
  const volume = useSelector(state => state.tts.volume);
  const enabled = useSelector(state => state.tts.enabled);

  const [ttsEnable, setTTSEnable] = useState(enabled);
  const [voiceName, setVoiceName] = useState(name);
  const [voiceSpeed, setVoiceSpeed] = useState(rate);
  const [voicePitch, setVoicePitch] = useState(pitch);
  const [ttsVolume, setTTSVolume] = useState(volume);
  const [sampleText, setSampleText] = useState('Hi, welcome to STEM Buddy. Just type some sample text and click the speak button to hear the same, using the specified voice settings.');

  const getTTSVoices = useCallback(() => {
    if ('speechSynthesis' in window) {
      const voices = window.speechSynthesis.getVoices();
      if (voices.length > 0) {
        setTTSVoices(voices);
      } else {
        setTimeout(getTTSVoices, 2000);
      }
    }
  }, []);

  useEffect(() => {
    if(ttsVoices.length === 0) {
      getTTSVoices();
    }
  }, [getTTSVoices, ttsVoices]);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  const handleTTSEnableChange = (value) => {
    setTTSEnable(value);
  };

  const handleSaveTTSSettings = (e) => {
    e.preventDefault();
    dispatch(setTTSVoiceParams({
      enabled: ttsEnable,
      voiceName: voiceName,
      pitch: voicePitch,
      rate: voiceSpeed,
      volume: ttsVolume,
    }));
  };

  const handleSpeakSampleSentence = (e) => {
    e.preventDefault();
    const msg = new SpeechSynthesisUtterance();
    msg.text = sampleText;
    msg.volume = ttsVolume / 100;
    msg.rate = voiceSpeed;
    msg.pitch = voicePitch;
    msg.voice = ttsVoices.find(voice => voice.name === voiceName);
    window.speechSynthesis.speak(msg);
  };

  return (
    <div className="App">
      <header className="App-header">
        <ApplicationBar back="/" title={'Text to Speech'} />
      </header>
      <Box sx={{mt:1, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', overflowY: 'scroll'}}>
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <ListItem>
            <ListItemIcon>
              <RecordVoiceOverIcon />
            </ListItemIcon>
            <ListItemText id="switch-list-label-tts" primary="Enable TTS" />
            <Switch
              edge="end"
              onChange={e => handleTTSEnableChange(e.target.checked)}
              checked={ttsEnable}
              inputProps={{
                'aria-labelledby': 'switch-list-label-tts',
              }}
            />
          </ListItem>
        </List>
        <Box sx={{width: '100%'}}>
          <FormControl sx={{ m: 1, pb: 1, display: 'flex', justifyContent: 'stretch', flexDirection: 'column', textAlign: 'left'}}>
            <InputLabel id="select-voice-name-label">Voice Name</InputLabel>
            <Select
              labelId="select-voice-name-label"
              id="select-voice-name"
              value={ttsVoices.length === 0 ? '' : voiceName}
              onChange={e => setVoiceName(e.target.value)}
              input={<OutlinedInput label="Voice Name" />}
              MenuProps={MenuProps}
              disabled={!ttsEnable}
            >
              {ttsVoices.map((voice) => (
                <MenuItem
                  key={voice.name}
                  value={voice.name}
                  style={getStyles(voice.name, voiceName, theme)}
                >
                  {voice.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, pb: 1, display: 'flex', justifyContent: 'stretch', flexDirection: 'column', textAlign: 'left'}}>
            <InputLabel id="select-voice-speed-label">Speed</InputLabel>
            <Select
              labelId="select-voice-speed-label"
              id="select-voice-speed"
              value={voiceSpeed}
              onChange={e => setVoiceSpeed(e.target.value)}
              input={<OutlinedInput label="Speed" />}
              MenuProps={MenuProps}
              disabled={!ttsEnable}
            >
              {voiceSpeeds.map((speed) => (
                <MenuItem
                  key={speed}
                  value={speed}
                  style={getStyles(speed, voiceSpeed, theme)}
                >
                  {speed}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, pb: 1, display: 'flex', justifyContent: 'stretch', flexDirection: 'column', textAlign: 'left'}}>
            <InputLabel id="select-voice-pitch-label">Pitch</InputLabel>
            <Select
              labelId="select-voice-pitch-label"
              id="select-voice-pitch"
              value={voicePitch}
              onChange={e => setVoicePitch(e.target.value)}
              input={<OutlinedInput label="Pitch" />}
              MenuProps={MenuProps}
              disabled={!ttsEnable}
            >
              {voicePitches.map((pitch) => (
                <MenuItem
                  key={pitch}
                  value={pitch}
                  style={getStyles(pitch, voicePitch, theme)}
                >
                  {pitch}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{display:'flex', justifyContent: 'start', ml: 1}}>
            <Typography>{'Volume'}</Typography>
          </Box>
          <Stack direction="row" spacing={1} sx={{alignItems:"center", marginTop: 2, ml: 1, mr: 1, pb: 2}}>
            <VolumeDownIcon/>
            <Slider
              value={ttsVolume}
              aria-label="Default"
              disabled={!ttsEnable}
              valueLabelDisplay="auto"
              onChange={(event, newValue) => setTTSVolume(newValue)} />
            <VolumeUpIcon/>
          </Stack>
          <Button variant="contained" sx={{mt:2}} onClick={handleSaveTTSSettings}>Save</Button>
          <Box sx={{mt:5, ml:2, mr:2}}>
            <TextField
              id="tts-test-text"
              label="Sample Text"
              multiline
              rows={4}
              value={sampleText}
              onChange={e => setSampleText(e.target.value)}
              sx={{mt: 3, width: '100%'}}
            />
          </Box>
          <Button variant="contained" sx={{mt:2, mb:2}} onClick={handleSpeakSampleSentence}>Speak</Button>
        </Box>
      </Box>
    </div>
  )
}

TTS.propTypes = {}

export default TTS
