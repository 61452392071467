import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LinkIcon from '@mui/icons-material/Link';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from "react-router-dom";
import IPAddressDialog from '../IPAddressDialog';

function ApplicationBar({back, title}) {
  const navigate = useNavigate();
  const [openIPAddressDialog, setOpenIPAddressDialog] = useState(false);

  const handleClickOpenIPAddressDialog = () => {
    setOpenIPAddressDialog(true);
  };

  const handleCloseIPAddressDialog = () => {
    setOpenIPAddressDialog(false);
  };

  const requestFullScreen = (element) => {
    const requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen;
    if (requestMethod) { // Native full screen.
      requestMethod.call(element);
    }
  };

  const handleLeftIconClick = (e) => {
    e.preventDefault();
    if (back !== "") {
      navigate(back);
    }
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={e => handleLeftIconClick(e)}
        >
          {back ? <ArrowBackIosNewIcon /> : <MenuIcon />}
          {title ? <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}> &nbsp; {title} </Typography> : null}
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} onClick={() => requestFullScreen(document.body)}>
          {title ? '' : 'STEM Buddy'}
        </Typography>
        <Box>
          <IconButton aria-label="connect" sx={{mr:1}} onClick={handleClickOpenIPAddressDialog}>
            <LinkIcon sx={{color: "#FFF"}} />
          </IconButton>
          <IconButton aria-label="settings">
            <SettingsIcon sx={{color: "#FFF"}} />
          </IconButton>
        </Box>
      </Toolbar>
      <IPAddressDialog open={openIPAddressDialog} handleClose={handleCloseIPAddressDialog} />
    </AppBar>
  );
}

ApplicationBar.propTypes = {}

export default ApplicationBar;
