import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ApplicationBar from '../../components/ApplicationBar';
import { useDispatch } from 'react-redux';
import { setRecognizedSpeech } from '../../reducers/speechRecognition/speechRecognitionSlice';
import { Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import KeyboardVoiceOutlinedIcon from '@mui/icons-material/KeyboardVoiceOutlined';
import './index.css';

function SpeechCommandsRecognition(props) {
  const dispatch = useDispatch();
  const speechRecognitionBtn = useRef(null);
  const [isListening, setIsListening] = useState(false);
  const [sendAutomatically, setSendAutomatically] = useState(true);
  const [lowerCaseConvert, setLowerCaseConvert] = useState(true);
  const [recognizedText, setRecognizedText] = useState('');
  const [speechLang, setSpeechLang] = useState('en-US');

  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  const recognition = new SpeechRecognition();
  recognition.continuous = false;
  recognition.interimResults = false;
  recognition.maxAlternatives = 1;

  recognition.onstart = (event) => {
    event.preventDefault();
    setIsListening(true);
  };

  recognition.onend = (event) => {
    event.preventDefault();
    setIsListening(false);
  };

  recognition.onerror = (event) => {
    event.preventDefault();
    setIsListening(false);
  };

  recognition.onresult = (event) => {
    const last = event.results.length - 1;
    let text = event.results[last][0].transcript.trim();
    if (text && text.length > 0) {
      text = lowerCaseConvert ? text.toLowerCase() : text;
      setRecognizedText(text);
      if (sendAutomatically) {
        dispatch(setRecognizedSpeech(text));
        setTimeout(() => {
          dispatch(setRecognizedSpeech(''));
        }, 1000);
      }
    }
  };

  const sendRecognizedSpeech = (e) => {
    e.preventDefault();
    dispatch(setRecognizedSpeech(recognizedText));
    setTimeout(() => {
      dispatch(setRecognizedSpeech(''));
    }, 1000);
  };

  const startSpeechRecognition = (e) => {
    e.preventDefault();
    if(isListening) {
      recognition.stop();
      setIsListening(false);
    } else {
      recognition.lang = speechLang;
      recognition.start();
      setIsListening(true);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <ApplicationBar back="/" title={'Speech Recognition'} />
      </header>
      <Box sx={{mt:1, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', overflowY: 'scroll'}}>
        <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', m:5, mt:5}}>
          <IconButton onClick={startSpeechRecognition} ref={speechRecognitionBtn} color="primary" size="large" sx={{mt: 5, mb: 5}}>
            <div className="pulse-ring" style={{animation: isListening ? 'pulsate infinite 1.5s' : ''}}>
              <KeyboardVoiceOutlinedIcon sx={{fontSize: '7rem'}}/>
            </div>
          </IconButton>
        </Box>
      </Box>
      <Box sx={{mt:2, ml:2, mr:2}}>
        <TextField
          id="speech-recognised-text"
          label="Recognized Text"
          value={recognizedText}
          InputProps={{
            readOnly: true,
          }}
          onChange={(e) => setRecognizedText(e.target.value)}
          sx={{mt: 0, width: '100%'}}
        />
      </Box>
      <Box sx={{mt:2, ml:2, mr:2, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
        <FormControl fullWidth>
          <InputLabel id="language-select-label">Language</InputLabel>
          <Select
            labelId="language-select-label"
            id="language-select"
            value={speechLang}
            label="Language"
            sx={{textAlign: "left"}}
            onChange={e => setSpeechLang(e.target.value)}
          >
            <MenuItem value={'en-US'}>English</MenuItem>
            <MenuItem value={'hi-IN'}>Hindi</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{width: '100%'}} component="fieldset" variant="standard">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={lowerCaseConvert} onChange={e => setLowerCaseConvert(e.target.checked)} name="gilad" disabled/>
              }
              label="Convert to lowercase before sending"
            />
            <FormControlLabel
              control={
                <Checkbox checked={sendAutomatically} onChange={e => setSendAutomatically(e.target.checked)} name="gilad" />
              }
              label="Send automatically recognized text"
            />
          </FormGroup>
        </FormControl>
        <Button variant="contained" sx={{mt:2, width: '100%'}} onClick={sendRecognizedSpeech}>Send</Button>
      </Box>
    </div>
  )
}

SpeechCommandsRecognition.propTypes = {}

export default SpeechCommandsRecognition
