import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ApplicationBar from '../../components/ApplicationBar';
import { useSelector, useDispatch } from 'react-redux';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function PinsMonitor(props) {
  const [tab, setTab] = useState(0);
  const analogPins = useSelector(state => state.gpioMonitor.analog);
  const digitalPins = useSelector(state => state.gpioMonitor.digital);

  // const [analogPins, setAnalogPins] = useState([
  //   {
  //     pin: 31,
  //     value: 1435,
  //   },
  //   {
  //     pin: 32,
  //     value: 1435,
  //   },
  //   {
  //     pin: 33,
  //     value: 1435,
  //   },
  //   {
  //     pin: 34,
  //     value: 1435,
  //   },
  //   {
  //     pin: 35,
  //     value: 1435,
  //   },
  //   {
  //     pin: 36,
  //     value: 1435,
  //   }
  // ]);
  // const [digitalPins, setDigitalPins] = useState([
  //   {
  //     pin: 12,
  //     value: 'OFF',
  //   },
  //   {
  //     pin: 13,
  //     value: 'ON',
  //   },
  //   {
  //     pin: 14,
  //     value: 'OFF',
  //   },
  //   {
  //     pin: 15,
  //     value: 'ON',
  //   },
  //   {
  //     pin: 16,
  //     value: 'OFF',
  //   },
  //   {
  //     pin: 18,
  //     value: 'OFF',
  //   },
  // ]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box className="App" sx={{display:"flex", flexDirection: "column"}}>
      <header className="App-header">
        <ApplicationBar back="/" title={'GPIO Monitor'} />
      </header>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab} onChange={handleTabChange} aria-label="gpio pins tabs" variant="fullWidth">
            <Tab label="Analog" {...a11yProps(0)} />
            <Tab label="Digital" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={tab} index={0}>
          <Box sx={{ bgcolor: 'background.paper', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 0, mt: 2 }}>
            <Grid container spacing={{ xs: 4, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }} >
              {analogPins.map((analog, index) => (
                <Grid item xs={2} sm={4} md={4} key={`analog-pin-${analog.pin}`}>
                  <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                    <Button>GPIO {analog.pin}</Button>
                    <Button>{analog.value}</Button>
                  </ButtonGroup>
                </Grid>
              ))}
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Box sx={{ bgcolor: 'background.paper', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 0, mt: 2 }}>
            <Grid container spacing={{ xs: 4, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }} >
              {digitalPins.map((digital, index) => (
                <Grid item xs={2} sm={4} md={4} key={`digital-pin-${digital.pin}`}>
                  <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                    <Button>GPIO {digital.pin}</Button>
                    {
                      digital.value === 'ON' ? (
                        <Button sx={{color: 'green'}}><FontAwesomeIcon icon={faLightbulb} style={{marginRight: "5px"}}/> ON&nbsp; </Button>
                      ) : (
                        <Button sx={{color: 'red'}}><FontAwesomeIcon icon={faLightbulb} style={{marginRight: "5px"}}/> OFF</Button>
                      )
                    }
                  </ButtonGroup>
                </Grid>
              ))}
            </Grid>
          </Box>
        </TabPanel>
      </Box>
    </Box>
  )
}

PinsMonitor.propTypes = {}

export default PinsMonitor
