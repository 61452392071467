import { createSlice } from '@reduxjs/toolkit'

export const homeAutomationSlice = createSlice({
  name: 'homeAutomation',
  initialState: {
    switches: [
    ],
    lastModifiedSwitch: -1,
  },
  reducers: {
    addSwitch: (state, action) => {
      const name = action.payload.name;
      const switchState = action.payload.state;
      state.switches.push({name: name, state: switchState});
    },
    resetElectricSwitches: (state) => {
      state.switches = [];
    },
    setSwitchState: (state, action) => {
      const index = action.payload.index;
      const switchState = action.payload.state;
      if (index !== -1 && index < state.switches.length) {
        state.switches[index].state = switchState;
        state.lastModifiedSwitch = index;
      }
    },
  }
})

// Action creators are generated for each case reducer function
export const { addSwitch, setSwitchState, resetElectricSwitches  } = homeAutomationSlice.actions

export default homeAutomationSlice.reducer