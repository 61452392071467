import { createSlice } from '@reduxjs/toolkit'

export const sensorsSlice = createSlice({
  name: 'sensors',
  initialState: {
    linearAccelerationEnabled: false,
    linearAcceleration: {
      x: 0,
      y: 0,
      z: 0,
    },
    gyroscopeEnabled: false,
    gyroscope: {
      x: 0,
      y: 0,
      z: 0,
    },
    magnetometerEnabled: false,
    magnetometer: {
      x: 0,
      y: 0,
      z: 0,
    },
    ambientLightEnabled: false,
    ambientLight: 0,
    gpsEnabled: false,
    gps: {
      latitude: 0,
      longitude: 0,
      altitude: 0,
      accuracy: 0,
      altitudeAccuracy: 0,
      heading: 0,
      speed: 0,
    },
  },
  reducers: {
    setLinearAccelerometerReading: (state, action) => {
      state.linearAcceleration = action.payload;
    },
    setEnableLinearAccelerometer: (state, action) => {
      state.linearAccelerationEnabled = action.payload;
      if(!action.payload) {
        state.linearAcceleration = {
          x: 0,
          y: 0,
          z: 0,
        };
      }
    },
    setGyroscopeReading: (state, action) => {
      state.gyroscope = action.payload;
    },
    setEnableGyroscope: (state, action) => {
      state.gyroscopeEnabled = action.payload;
      if(!action.payload) {
        state.gyroscope = {
          x: 0,
          y: 0,
          z: 0,
        };
      }
    },
    setMagnetometerReading: (state, action) => {
      state.magnetometer = action.payload;
    },
    setEnableMagnetometer: (state, action) => {
      state.magnetometerEnabled = action.payload;
      if(!action.payload) {
        state.magnetometer = {
          x: 0,
          y: 0,
          z: 0,
        };
      }
    },
    setAmbientLightReading: (state, action) => {
      state.ambientLight = action.payload;
    },
    setEnableAmbientLight: (state, action) => {
      state.ambientLightEnabled = action.payload;
      if(!action.payload) {
        state.ambientLight = 0;
      }
    },
    setGPSReading: (state, action) => {
      state.gps = action.payload;
    },
    setEnableGPS: (state, action) => {
      state.gpsEnabled = action.payload;
      if(!action.payload) {
        state.gps = {
          latitude: 0,
          longitude: 0,
          altitude: 0,
          accuracy: 0,
          altitudeAccuracy: 0,
          heading: 0,
          speed: 0,
        };
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const  { setLinearAccelerometerReading, setEnableLinearAccelerometer,
                setGyroscopeReading, setEnableGyroscope,
                setMagnetometerReading, setEnableMagnetometer,
                setAmbientLightReading, setEnableAmbientLight,
                setGPSReading, setEnableGPS,
              } = sensorsSlice.actions

export default sensorsSlice.reducer