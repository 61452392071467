import { createSlice } from '@reduxjs/toolkit'

export const musicSlice = createSlice({
  name: 'music',
  initialState: {
    notes: [
      {
        name: "C4",
        path: "/audio/C4.mp3"
      },
      {
        name: "D4",
        path: "/audio/D4.mp3"
      },
      {
        name: "E4",
        path: "/audio/E4.mp3"
      },
      {
        name: "F4",
        path: "/audio/F4.mp3"
      },
      {
        name: "G4",
        path: "/audio/G4.mp3"
      },
      {
        name: "A4",
        path: "/audio/A4.mp3"
      },
      {
        name: "B4",
        path: "/audio/B4.mp3"
      },
      {
        name: "C5",
        path: "/audio/C5.mp3"
      }
    ]
  },
  reducers: {
    playNoteByPath: (state, action) => {
      const audio = new Audio(action.payload);
      audio.play();
    },
    playNoteByName: (state, action) => {
      const path = state.notes.find(note => note.name === action.payload).path;
      if (path) {
        const audio = new Audio(path);
        audio.play();
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const { playNoteByPath, playNoteByName } = musicSlice.actions

export default musicSlice.reducer