import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ApplicationBar from '../../components/ApplicationBar';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import { useSelector, useDispatch } from 'react-redux';
import { playNoteByPath } from '../../reducers/music/musicSlice';

const fabStyle = {
  position: 'absolute',
  bottom: 16,
  right: 16,
  fontSize: '2rem'
};

function Music(props) {
  const dispatch = useDispatch();
  const notes = useSelector(state => state.music.notes);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  const handleOnPlayMusicNote = (note) => {
    dispatch(playNoteByPath(note.path));
  };

  return (
    <div className="App">
      <header className="App-header">
        <ApplicationBar back="/" title={'Music'} />
      </header>
      <Box sx={{mt:1, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', overflowY: 'scroll'}}>
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {
              notes.map((note, index) => (
                <React.Fragment key={`music-note-${note.name}`}>
                  <ListItem disablePadding alignItems="flex-start" secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  }>
                    <ListItemButton onClick={e => handleOnPlayMusicNote(note)}>
                      <ListItemIcon>
                        <PlayCircleIcon sx={{ fontSize: 40 }} color="action"/>
                      </ListItemIcon>
                      <ListItemText
                        primary={note.name}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: 'inline', mr:1 }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {note.path}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </React.Fragment>
              ))
            }
          </List>
        </Box>
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
          <Fab color="primary" aria-label="add" sx={fabStyle} >
            <AddIcon sx={{width: '3rem', height: '3rem'}}/>
          </Fab>
        </Box>
      </Box>
    </div>
  )
}

Music.propTypes = {}

export default Music
