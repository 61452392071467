import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import SendIcon from '@mui/icons-material/Send';
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import ApplicationBar from '../../components/ApplicationBar';
import {useSelector, useDispatch} from 'react-redux';
import moment from 'moment';
import { sendOutgoingMessage } from '../../reducers/terminal/terminalSlice';
import './serial.css';

function SerialCommunication(props) {
  const dispatch = useDispatch();
  const messages = useSelector(state => state.terminal.messages);
  const [userMsg, setUserMsg] = useState('');

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  const getSenderTimeStamp = (msg) => {
    return `${msg.direction === "incoming" ? "Device " : "STEM Buddy "}, ${moment(msg.timeStamp).fromNow()}`;
  };

  const handleSendMessage = (e) => {
    if(e) {
      e.preventDefault();
    }
    dispatch(sendOutgoingMessage(userMsg));
    setUserMsg('');
  };

  return (
    <Box className="App" sx={{display:"flex", flexDirection: "column", minHeight:"100vh"}}>
      <header className="App-header">
        <ApplicationBar back="/" title={'Serial Communication'} />
      </header>
      <Box sx={{display:"flex", flexDirection:"column", justifyContent:"space-between", maxHeight:`calc((var(--vh, 1vh) * 100) - 56px)`, flexGrow: 1}}>
        <List className={"messageArea"}>
          {
            messages.map((message, index) => {
              return (
                <ListItem key={`terminal-message-${index}`}>
                  <Grid container>
                    <Grid item xs={12}>
                      <ListItemText align={message.direction === "incoming" ? "left" : "right"} primary={message.msg}></ListItemText>
                    </Grid>
                    <Grid item xs={12}>
                      <ListItemText align={message.direction === "incoming" ? "left" : "right"} secondary={getSenderTimeStamp(message)}></ListItemText>
                    </Grid>
                  </Grid>
                </ListItem>
              )
            })
          }
        </List>
        <OutlinedInput
          id="outlined-adornment-password"
          type={'text'}
          value={userMsg}
          onChange={(e) => {setUserMsg(e.target.value)}}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              handleSendMessage(null);
            }
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="send message"
                onClick={handleSendMessage}
                edge="end"
                disabled={userMsg.length === 0}
              >
                <SendIcon />
              </IconButton>
            </InputAdornment>
          }
          placeholder="Type message"
          sx={{width: '100%', color: "#000"}}
        />
      </Box>
    </Box>
  );
}

SerialCommunication.propTypes = {};

export default SerialCommunication;
